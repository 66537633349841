var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"nav",staticClass:"nav",class:_vm.mouseInHeader && 'nav--open',style:(_vm.cssVars)},[_c('ul',{staticClass:"nav__list"},[(_vm.canCurrentUserSeeActivities)?_c('li',{staticClass:"nav__item"},[_c('RouterLink',{staticClass:"nav__link",class:{
          'nav__link--active': _vm.$route.matched[0].meta.title === 'Detalhes'
        },attrs:{"to":_vm.activitiesRoute,"active-class":"nav__link--active"},nativeOn:{"click":function($event){return _vm.checkAppVersion.apply(null, arguments)}}},[_c('HeartIcon',{staticClass:"nav__icon",class:{
            'nav__icon--active':
              _vm.$route.matched[0].meta.title === 'Minhas Atividades' ||
              _vm.$route.matched[0].meta.title === 'Detalhes'
          },attrs:{"icon":"graphic-icon-menu-minhas-atividades","size":"medium-large"}}),_c('div',{staticClass:"nav__label"},[_vm._v("Minhas atividades")])],1)],1):_vm._e(),_c('li',{staticClass:"nav__item"},[_c('RouterLink',{staticClass:"nav__link",attrs:{"to":"/todas-as-contas","active-class":"nav__link--active"},nativeOn:{"click":function($event){return _vm.checkAppVersion.apply(null, arguments)}}},[_c('HeartIcon',{staticClass:"nav__icon",class:{
            'nav__icon--active':
              _vm.$route.matched[0].meta.title === 'Todas as contas'
          },attrs:{"icon":"graphic-icon-menu-todas-as-contas","size":"medium-large"}}),_c('div',{staticClass:"nav__label"},[_vm._v("Todas as contas")])],1)],1),(_vm.canCurrentUserSeeDashboard)?_c('li',{staticClass:"nav__item"},[_c('RouterLink',{staticClass:"nav__link",attrs:{"to":"/dashboard","active-class":"nav__link--active"},nativeOn:{"click":function($event){return _vm.checkAppVersion.apply(null, arguments)}}},[_c('HeartIcon',{staticClass:"nav__icon",class:{
            'nav__icon--active': _vm.$route.matched[0].meta.title === 'Dashboard'
          },attrs:{"icon":"graphic-icon-menu-dashboard","size":"medium-large"}}),_c('div',{staticClass:"nav__label"},[_vm._v("Dashboard")])],1)],1):_vm._e(),(_vm.canCurrentUserSeePerformance)?_c('li',{staticClass:"nav__item"},[_c('RouterLink',{staticClass:"nav__link",attrs:{"to":"/desempenho","active-class":"nav__link--active"},nativeOn:{"click":function($event){return _vm.checkAppVersion.apply(null, arguments)}}},[_c('HeartIcon',{staticClass:"nav__icon",class:{
            'nav__icon--active': _vm.$route.matched[0].meta.title === 'Desempenho'
          },attrs:{"icon":"graphic-icon-menu-desempenho","size":"medium-large"}}),_c('div',{staticClass:"nav__label"},[_vm._v("Desempenho")])],1)],1):_vm._e(),(_vm.canCurrentUserSeePanel)?_c('li',{staticClass:"nav__item"},[_c('RouterLink',{staticClass:"nav__link",attrs:{"to":_vm.panelsRoute,"active-class":"nav__link--active"},nativeOn:{"click":function($event){return _vm.checkAppVersion.apply(null, arguments)}}},[_c('HeartIcon',{staticClass:"nav__icon",class:{
            'nav__icon--active': _vm.$route.matched[0].meta.title === 'Painéis'
          },attrs:{"icon":"graphic-icon-menu-paineis","size":"medium-large"}}),_c('div',{staticClass:"nav__label"},[_vm._v("Painéis")])],1)],1):_vm._e(),(_vm.canCurrentUserEditUsers)?_c('li',{staticClass:"nav__item",attrs:{"data-test":"settings-icon"}},[_c('RouterLink',{staticClass:"nav__link",attrs:{"to":"/configuracoes","active-class":"nav__link--active"},nativeOn:{"click":function($event){return _vm.checkAppVersion.apply(null, arguments)}}},[_c('HeartIcon',{staticClass:"nav__icon",class:{
            'nav__icon--active':
              _vm.$route.matched[0].meta.title === 'Configurações'
          },attrs:{"icon":"graphic-icon-menu-configura-es","size":"medium-large"}}),_c('div',{staticClass:"nav__label"},[_vm._v("Configurações")])],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }