<script>
import { mapGetters } from 'vuex';

const LogoCiclys = () =>
  import(
    /* webpackChunkName: "svg-ciclys-short" */
    '@/core/Assets/logos/ciclys-short.svg'
  );
const ArrowDown = () =>
  import(
    /* webpackChunkName: "svg-arrow-down" */
    '@/core/Assets/arrows/arrow-down.svg'
  );
import RoutePath from '@/route-path';

const CompanyLogo = () =>
  import(
    /* webpackChunkName: "main-company-logo-component" */
    '@/core/Components/CompanyLogo'
  );
const MainSearch = () =>
  import(
    /* webpackChunkName: "main-search-component" */
    '@/core/Components/Main/Search'
  );
const MainDropdown = () =>
  import(
    /* webpackChunkName: "main-dropdown-component" */
    '@/core/Components/Main/Dropdown'
  );
const Badge = () =>
  import(
    /* webpackChunkName: "activity-badge-component" */
    '@/core/Components/Activity/Badge'
  );
const MainNotifications = () =>
  import(
    /* webpackChunkName: "main-notifications-component" */
    '@/core/Components/Main/Notifications'
  );

const SupportActions = () =>
  import(
    /* webpackChunkName: "support-actions-component" */
    '@/core/Components/SupportActions.vue'
  );

export default {
  components: {
    ArrowDown,
    CompanyLogo,
    Badge,
    MainSearch,
    MainDropdown,
    LogoCiclys,
    MainNotifications,
    SupportActions
  },
  props: {
    mouseInNav: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    dropdown: false
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('supportActions', ['canShowSupportActions']),
    ...mapGetters('allBills', ['list']),
    name() {
      return this.currentUser?.name ?? '';
    },
    isUserPage() {
      return this.$route?.name === RoutePath.SETTINGS_USER;
    },
    showSearch() {
      if (!this.$route?.name) {
        return false;
      }
      if (RoutePath.ALL_BILLS === this.$route.name) {
        return !!this.$route.query?.search || !!this.allBillsList?.length > 0;
      }
      return [
        RoutePath.ACTIVITY,
        RoutePath.SETTINGS_USER,
        RoutePath.SETTINGS_USER_ACTIVITY
      ].includes(this.$route.name);
    },
    activitiesRoute() {
      const activityId = this.$route?.params?.activityId;
      const query = this.$route?.query;
      if (activityId !== 0 && !activityId) {
        return {
          name: RoutePath.ACTIVITY
        };
      }
      return {
        name: RoutePath.ACTIVITY,
        params: { activity_id: activityId },
        query
      };
    },
    isActivitiesRoute() {
      return this.$route?.name === RoutePath.ACTIVITY;
    },
    isPanelsRoute() {
      return (
        this.$route?.name === RoutePath.PANELS ||
        this.$route?.name === RoutePath.PANELS_DETAIL
      );
    }
  }
};
</script>

<template>
  <header
    class="header"
    :class="{
      'header--opened': mouseInNav,
      'header--shadow': !isActivitiesRoute && !isPanelsRoute
    }"
    data-test="header"
  >
    <div
      class="header__ciclys"
      :class="mouseInNav && 'header__ciclys--show'"
      @mouseenter="$emit('mouseenter-logo')"
      @mouseleave="$emit('mouseleave-logo')"
    >
      <RouterLink :to="activitiesRoute">
        <LogoCiclys class="header__open" />
        <LogoCiclys class="header__open header__open--smaller" />
      </RouterLink>
    </div>

    <div class="header__spacer" />

    <MainSearch
      :key="`header-search-${$route.name}`"
      class="header__search"
      :class="{ 'header__search--hidden': !showSearch }"
      :show-search-bar="showSearch"
      :is-advanced-search="!isUserPage"
    />

    <MainNotifications />

    <div class="header__separator" />

    <CompanyLogo class="header__logo" />

    <div class="header__separator" />

    <div
      class="header__user"
      data-test="header-user"
      @click="dropdown = !dropdown"
    >
      <Badge :text="name" rounded />
      <ArrowDown
        class="header__arrow-down"
        :class="{ 'header__arrow-down--active': dropdown }"
      />

      <MainDropdown v-if="dropdown" @close="dropdown = false" />
    </div>

    <SupportActions v-if="canShowSupportActions" data-test="support-actions" />
  </header>
</template>

<style lang="scss">
.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  display: flex;
  position: fixed;
  align-items: center;
  min-height: 70px;
  background-color: var(--colors-neutral-primary);

  &--shadow {
    &:before {
      content: '';
      background-color: var(--colors-neutral-primary);
      display: block;
      position: absolute;
      top: 0;
      left: 90px;
      right: 0;
      bottom: 0;
      box-shadow: var(--shadows-level4);

      @media (max-width: 1200px) {
        left: 60px;
        transition: var(--transition);
      }
    }

    @media (max-width: 1200px) {
      &.header--opened:before {
        left: 90px;
      }
    }
  }

  &__open {
    width: 60px;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    flex-shrink: 0;

    &--smaller {
      width: 50px;
      height: 33px;
      display: flex;
    }
  }

  &__ciclys {
    width: 90px;
    position: absolute;
    background: var(--colors-neutral-primary);
    overflow: hidden;

    .header__open--smaller {
      display: none;
    }
    @media (max-width: 1200px) {
      transition: width var(--transition);
      width: 60px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      .header__open {
        display: none;
      }
      .header__open--smaller {
        display: flex;
      }

      &:hover,
      &--show {
        .header__open {
          display: flex;
        }
        .header__open--smaller {
          display: none;
        }
        width: 90px;
        margin-right: -30px;
      }
    }
  }

  &__spacer {
    min-width: 90px;
    height: 100%;
    display: flex;
    @media (max-width: 1200px) {
      min-width: 60px;
      height: 100%;
    }
  }

  &__action {
    color: var(--colors-neutral-base);
    margin-right: 32px;
  }

  &__separator {
    height: 40px;
    border-left: 1px solid var(--colors-neutral-lightest);
    margin: 0 16px 0 16px;
    z-index: 15;
  }

  &__user {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    padding: 4px 24px 4px 0;
  }

  &__arrow-down {
    color: var(--colors-neutral-dark);
    margin-left: 12px;
    transition: var(--transition);

    &--active {
      color: var(--colors-brand-darkest);
      transform: rotate(180deg);
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    margin-left: 0;
    transition: var(--transition);
  }

  .slide-enter,
  .slide-leave-to {
    margin-left: -316px;
  }
  &__search {
    max-width: calc(40rem + 90px);
    margin: 0 auto;
    padding: 0 40px;
    &--hidden {
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }
}
</style>
