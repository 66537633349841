import Service from '@/core/Services/service';
import { Activity } from '@types';
import IBillActivity from '@/core/Interfaces/bill_activity';
import TPagination from '@/core/Interfaces/pagination';
import { CancelToken } from 'axios';
import IUserUpdateOrCreate from '../Interfaces/update-or-create';
import IUser from '../Interfaces/user';

class Factory extends Service {
  async createUser(params: IUserUpdateOrCreate): Promise<IUser> {
    const result = await this.post('/users', params);
    return result?.data;
  }

  async updateUser(
    userId: IUser['userId'],
    params: IUserUpdateOrCreate
  ): Promise<IUser> {
    const result = await this.patch(`/users/${userId}`, params);
    return result?.data;
  }

  async deleteUser(userId: IUser['userId']): Promise<void> {
    await this.delete(`/users/${userId}`);
  }

  async getUsers(
    params: Record<string, any>,
    cancelToken?: CancelToken
  ): Promise<TPagination<IUser>> {
    const result = await this.get('/users', params, cancelToken);
    return result?.data;
  }

  async getUserById(userId: IUser['userId']): Promise<IUser> {
    const result = await this.get(`/users/${userId}`);
    return result?.data;
  }

  async getAllEnabledToBillActivity(
    billActivityId: IBillActivity['billActivityId']
  ): Promise<IUser[]> {
    const result = await this.get(`/users/bill-activities/${billActivityId}`);
    return result?.data;
  }

  async getAllResponsibleByActivity(
    activityId: Activity['activityId']
  ): Promise<IUser[]> {
    const result = await this.get(`/users/activities/${activityId}`);
    return result?.data;
  }

  async saveResponsibleById(
    billActivityId: IBillActivity['billActivityId'],
    responsibleId: IUser['userId']
  ) {
    await this.post(
      `/bill_activities/${billActivityId}/responsible/${responsibleId}`
    );
  }

  async removeResponsible(billActivityId: IBillActivity['billActivityId']) {
    await this.delete(`/bill_activities/${billActivityId}/responsible`);
  }
}

export default new Factory();
