import { ActionContext } from 'vuex';
import axios from '@/axios';
import IAuthStore from '../Interfaces/store';
import AuthService from '../Services';
import SupportUtil from '@/core/Utils/Support';

const addSupportScript = async ({
  dispatch,
  state,
  rootGetters,
  getters
}: Partial<ActionContext<IAuthStore, any>>) => {
  if (!getters.insertProductionScripts) {
    SupportUtil.instance.removeScript();
    return;
  }
  const companySelectString = 'company/select';
  if (!rootGetters[companySelectString].length) {
    await dispatch('company/fetch', null, { root: true });
  }

  const name = state.user?.name ?? state.name;
  const mail = state.user?.email ?? state.email;
  const companyId = state.user?.companyId ?? state.company_id;
  const empresa =
    rootGetters[companySelectString].find(
      (item: { value: string; label: string }) => +item.value === +companyId
    )?.label ?? '';

  const extraParams = {
    Telefone: state.user?.phoneNumber ?? state.phoneNumber,
    Empresa: empresa,
    Cliente: window.location.hostname.split('.')[0],
    Url: window.location.href
  };
  SupportUtil.instance.setSupportScript(name, mail, extraParams);
};

export default {
  async check(
    {
      commit,
      dispatch,
      state,
      rootGetters,
      getters
    }: ActionContext<IAuthStore, any>,
    is_guest: boolean
  ) {
    try {
      commit('SET_BUSY', 'check');
      /**
       * If we do not have a token, a company nor status is true, throw an error.
       */
      if (!state.token || !state.company_id || state.status === false) {
        throw new Error('Not Found');
      }

      /**
       * If we already checked and it's valid, just skip it.
       */
      if (state.status !== true) {
        axios.defaults.headers.common.Authorization = state.token;
        axios.defaults.headers.common['x-company-id'] = state.company_id;

        /**
         * Try to load everything we need.
         * If it fail, the user status will be false.
         */
        const [{ data }] = await Promise.all([
          AuthService.getCurrentUser(),
          dispatch('activity/fetch', null, {
            root: true
          }),
          dispatch('company/getConfigurations', null, {
            root: true
          })
        ]);
        commit('SET_USER', data);
        commit('SET_STATUS', true);
        addSupportScript({ dispatch, state, rootGetters, getters });
      }

      if (is_guest) {
        return {
          path: '/'
        };
      }
      return {};
    } catch (e: any) {
      commit('SET_STATUS', false);

      if (!is_guest) {
        return {
          path: '/auth'
        };
      }
      return {};
    } finally {
      commit('SET_BUSY');
    }
  },

  async logout({ commit, dispatch }: ActionContext<IAuthStore, any>) {
    commit('SET_COMPANY_ID', null);
    commit('SET_TOKEN', null);
    dispatch('clearAllStates', null, { root: true });
  },

  async login(
    { commit, dispatch }: ActionContext<IAuthStore, any>,
    {
      username,
      password,
      company_id
    }: { username: string; password: string; company_id: string }
  ) {
    commit('SET_BUSY', 'auth');
    commit('SET_COMPANY_ID', company_id);
    commit('SET_REMAINING_LOGIN_ATTEMPS', null);
    commit('SET_DEVICE_IDENTITY', null);

    try {
      const { headers } = await AuthService.setLogin({
        username,
        password
      });

      commit('SET_USERNAME', username);
      commit('SET_TOKEN', headers.authorization);
      if (headers['needs-two-factor-auth'] === 'true') {
        const deviceIdentity = headers['device-identity'];
        const { data } = await AuthService.submitTwoFactorCode(deviceIdentity);
        commit('SET_DEVICE_IDENTITY', deviceIdentity);
        commit('SET_PHONE_NUMBER', data?.phoneNumber);
        commit('SET_EMAIL', data?.email ?? '');
      }
    } catch (e: any) {
      if (e.response.data.message === 'CREDENTIALS_EXPIRED') {
        const errorData = e.response.data;
        dispatch('setUserRecovery', {
          name: errorData.name,
          username: errorData.username,
          token: errorData.resetPasswordToken
        });
        commit('SET_COMPANY_ID', company_id);

        throw new Error('CREDENTIALS_EXPIRED');
      }
      const remainingAttemps = e.response.data?.remainingAttemps;
      if (
        e.response.data.message === 'ACCOUNT_LOCKED' ||
        remainingAttemps === 0
      ) {
        commit('SET_ERROR', {
          key: 'username',
          value: null
        });
        throw new Error('ACCOUNT_LOCKED');
      }

      commit('SET_REMAINING_LOGIN_ATTEMPS', remainingAttemps);
      commit('SET_ERROR', {
        key: 'username',
        value:
          'Não foi possível efetuar o Login, verifique o seu usuário e a sua senha!'
      });

      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  async recover({ commit }: ActionContext<IAuthStore, any>, username: string) {
    commit('SET_BUSY', 'recover');
    try {
      commit('SET_USERNAME', username);
      const { data } = await AuthService.setRecover(username);
      commit('SET_PHONE_NUMBER', data.phoneNumber);
      commit('SET_EMAIL', data?.email ?? '');
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  async confirm(
    { commit, state }: ActionContext<IAuthStore, any>,
    token: string
  ) {
    commit('SET_BUSY', 'confirm');
    try {
      const { username } = state;
      const { data } = await AuthService.setConfirm(username, token);
      commit('SET_CODE', token);
      commit('SET_NAME', data.name);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  setUserRecovery(
    { commit }: ActionContext<IAuthStore, any>,
    {
      name,
      username,
      token
    }: {
      name: string;
      username: string;
      token: string;
    }
  ) {
    commit('SET_NAME', name);
    commit('SET_USERNAME', username);
    commit('SET_CODE', token);
  },

  async reset(
    { commit, state }: ActionContext<IAuthStore, any>,
    password: string
  ) {
    commit('SET_BUSY', 'reset');
    try {
      const { username } = state;
      const resp = await AuthService.setReset(username, {
        token: state.code,
        password
      });
      commit('SET_USERNAME', '');
      commit('SET_CODE', '');
      commit('SET_NAME', '');
      commit('SET_PHONE_NUMBER', '');
      return resp;
    } finally {
      commit('SET_BUSY');
    }
  }
};
