import Service from '@/core/Services/service';

class Factory extends Service {
  async getNotifications(params = {}) {
    const { data } = await this.get('/notifications', params);
    return data;
  }

  async setReaded(notificationId, readed) {
    return await this.post(
      `/notifications/${notificationId}/read/${readed}`,
      {}
    );
  }

  async createNotifications(notifications) {
    const { data } = await this.post('/notifications', notifications);
    return data;
  }

  async deleteNotifications(notifications) {
    return await this.delete(`/notifications/${notifications}`);
  }
}

export default new Factory();
