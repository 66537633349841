import UserUtil from '@/modules/Settings/Modules/User/Utils';
import { chunk } from '@/helpers';

export const transform = ({ sector, name: sublabel, activityId: value }) => ({
  value,
  sublabel,
  label: sector.name
});

export default {
  allowed: (state, getters) => {
    const filterIsUserActivity = getters.sorted.filter(
      ({ isUserActivity }) => isUserActivity
    );
    return filterIsUserActivity.sort((a, b) =>
      a.sector.name > b.sector.name ? 1 : -1
    );
  },
  sorted: (state) =>
    state.activities
      .slice()
      .sort((a, b) => (a.sector.name > b.sector.name ? 1 : -1)),
  activity: (state) =>
    state.activities.find(
      ({ activityId }) => +activityId === +state.activity
    ) || {},
  send_to: (state, getters) =>
    getters.sorted.find(({ activityId }) => +activityId === +state.send_to) ||
    {},
  select: (state, getters) => getters.sorted.map(transform),
  selectEnabled: (state, getters) =>
    getters.sorted.filter((activity) => activity.enabled).map(transform),
  filter: (state) => state.filter,
  status: (state) => state.status,
  allowed_select: (state, getters) => getters.allowed.map(transform),
  can_receive_pendency: (state, getters) =>
    getters.sorted.filter(({ canReceivePendency }) => canReceivePendency),
  selected_ids: (state) =>
    state.selected.map(({ billActivityId }) => billActivityId),
  searching: (state) => !!state.search,
  chunks: (state) => chunk(state.list, 3),
  list_chunks: (state) => chunk(state.list, UserUtil.limitPerPage),
  selected: (state) => state.selected,
  listBackward: (state) => state.listBackward,
  list: (state) => state.list,
  pagination: (state) => state.pagination,
  activities: (state) => state.activities,
  search: (state) => state.search,
  send_to_noordered: (state) => state.send_to,
  remove: (state) => state.remove,
  errors: (state) => state.errors,
  notFound: (state) => state.notFound,
  searchCode: (state) => state.searchCode,
  searchCodeOptions: (state) => state.searchCodeOptions,
  friendlyBillActivityLotId: (state) => state.friendlyBillActivityLotId,
  responsibleFilter: (state) => state.responsibleFilter,
  orderFilter: (state) => state.orderFilter,
  timeRangeFilter: (state) => state.timeRangeFilter,
  timeRangeTypeFilter: (state) => state.timeRangeTypeFilter,
  flagFilter: (state) => state.flagFilter,
  typeFilter: (state) => state.typeFilter,
  cancelToken: (state) => state.cancelToken,
  haveCancelled: (state) => state.haveCancelled
};
