import Vue from 'vue';
import Router from 'vue-router';
import NotFound from '@/core/Components/Error/NotFound';
import DashboardRouter from '@/modules/Dashboard/Routes';
import AllBillsRouter from '@/modules/AllBills/Routes';
import AuthRouter from '@/modules/Auth/Routes';
import PerformanceRouter from '@/modules/Performance/Routes';
import SettingsRouter from '@/modules/Settings/Routes';
import ActivitiesRouter from '@/modules/Activities/Routes';
import PanelsRouter from '@/modules/Panels/Routes';

Vue.config.devtools = true;
Vue.use(Router);

export default new Router({
  base: process.env.VUE_APP_DEVELOP_BRANCH || '/',
  mode: 'history',
  routes: [
    { path: '*', component: NotFound },
    ...AuthRouter,
    ...ActivitiesRouter,
    ...AllBillsRouter,
    ...DashboardRouter,
    ...SettingsRouter,
    ...PerformanceRouter,
    ...PanelsRouter
  ]
});
