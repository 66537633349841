import { pipe, useBusy, useErrors } from '@/helpers';
import HealthInsuranceFactory from '@/core/Services/healthInsurance';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  healthInsurances: []
});

const theState = getDefaultState();

const getters = {
  select: (state) =>
    state.healthInsurances.map(({ healthInsuranceId: value, name: label }) => ({
      value,
      label
    })),

  amount: (state) => state.healthInsurances.length,

  healthInsurances: (state) => state.healthInsurances
};

const mutations = {
  SET_HEALTH_INSURANCES(state, healthInsurances) {
    state.healthInsurances = healthInsurances;
  },

  SET_HEALTH_INSURANCES_BY_COMPANY(state, healthInsurances) {
    state.healthInsurances = healthInsurances;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async healthInsurances({ commit }) {
    commit('SET_BUSY', 'healthInsurances');
    try {
      const { data } = await HealthInsuranceFactory.getHealthInsurance();

      commit('SET_HEALTH_INSURANCES', data);
    } catch (e) {
      commit('SET_ERROR', {
        key: 'health_insurances',
        value: 'Erro na resposta do serviço'
      });
      commit('SET_HEALTH_INSURANCES');
    } finally {
      commit('SET_BUSY');
    }
  },
  async updateDeadlineDays({ commit }, updatedList) {
    commit('SET_HEALTH_INSURANCES', updatedList);
    const parseParam = (param) =>
      typeof param !== 'string' ? param : +param?.split(' ')[0];

    const newList = updatedList.map((healthInsurance) => ({
      deadlineDay: healthInsurance.deadlineDay,
      deliveryTimeAlgorithm: healthInsurance.deliveryTimeAlgorithm,
      deliveryTimeAlgorithmParam: parseParam(
        healthInsurance.deliveryTimeAlgorithmParam
      ),
      healthInsuranceId: healthInsurance.healthInsuranceId,
      incomingReceiveTimeParam: parseParam(
        healthInsurance.incomingReceiveTimeParam
      ),
      incomingAppealTimeParam: parseParam(
        healthInsurance.incomingAppealTimeParam
      )
    }));

    await HealthInsuranceFactory.updateDeadlineDays({
      healthInsuranceDeadlineDays: newList
    });
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
