import { pipe, useBusy, useErrors } from '@/helpers';
import CompanyFactory from '@/core/Services/company';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const theState = {
  companies: [],
  configurations: [],
  enabledModules: {}
};

const getters = {
  select: (state) =>
    state.companies.map(({ companyId: value, shortName: label }) => ({
      value,
      label
    })),
  companyLogo: (state) => {
    const logoConf = state.configurations.find(
      ({ code }) => code === 'ASSET_LOGO_URL'
    );
    if (!logoConf?.value) {
      return null;
    }
    return logoConf.value;
  },
  enabledModules: (state) => state.enabledModules
};

const mutations = {
  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
  SET_CONFIGURATIONS(state, configurations) {
    state.configurations = configurations;
  },
  SET_ENABLED_MODULES(state, modules) {
    state.enabledModules = modules;
  }
};

const actions = {
  async fetch({ commit }) {
    commit('SET_BUSY', 'fetch');

    const companies = await CompanyFactory.getCompanies();

    commit('SET_COMPANIES', companies);
    commit('SET_BUSY');
  },

  async getEnabledModules({ commit }) {
    commit('SET_BUSY', 'get_enabled_modules');
    const enabledModules = await CompanyFactory.getEnabledModules();
    commit('SET_ENABLED_MODULES', enabledModules);
    commit('SET_BUSY');
  },

  async getConfigurations({ commit, dispatch }) {
    commit('SET_BUSY', 'get_configurations');

    const configurations = await CompanyFactory.getFrontConfigurations();
    const assetsConfigurations = await CompanyFactory.getAssetsConfigurations();

    commit('SET_CONFIGURATIONS', [...configurations, ...assetsConfigurations]);
    commit('SET_BUSY');

    const carouselTime = configurations?.find(
      ({ code }) => code === 'F_DASH_CAROUSEL_TIME'
    );
    if (!carouselTime) {
      return;
    }

    dispatch('dashboard/setCarouselTransitionTime', +carouselTime.value, {
      root: true
    });
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
