import IUser from '@/modules/Settings/Modules/User/Interfaces/user';
import { IUserActivity, IUserActivityFull } from '../Interfaces/activity';

class UserUtil {
  newFeature: string;

  pegNewFeaturesStorageKey: string;

  limitPerPage: number;

  constructor() {
    this.newFeature = 'pendency-calendar-required';
    this.pegNewFeaturesStorageKey = '__peg_new_features';
    this.limitPerPage = 3 * 16; // 3 * 16 === 48
  }

  set newFeatureStorage(status: string) {
    window.localStorage.setItem(this.pegNewFeaturesStorageKey, status);
  }

  get newFeatureStorage(): string {
    return window.localStorage.getItem(this.pegNewFeaturesStorageKey);
  }

  /**
   * Generate background color from user or name
   * @param user User or name to get avatar color from
   */
  getColorByName(user: IUser | string) {
    if (typeof user === 'string') {
      return this.stringToHslColor(user);
    }
    if (!user?.name) {
      return this.stringToHslColor('PEG Contas');
    }
    return this.stringToHslColor(user.name);
  }

  // eslint-disable-next-line class-methods-use-this
  stringToHslColor(name: string) {
    const opts = {
      hue: [0, 360],
      sat: [10, 60],
      lit: [20, 60]
    };

    const range = function (hash: number, min: number, max: number) {
      const diff = max - min;
      const x = ((hash % diff) + diff) % diff;
      return x + min;
    };

    let hash = 0;
    if (name.length === 0) {
      return hash;
    }
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    const h = range(hash, opts.hue[0], opts.hue[1]);
    const s = range(hash, opts.sat[0], opts.sat[1]);
    const l = range(hash, opts.lit[0], opts.lit[1]);

    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  // eslint-disable-next-line class-methods-use-this
  userActivityFullToCommon(
    userActivityFull: IUserActivityFull[]
  ): IUserActivity[] {
    if (!userActivityFull?.length) {
      return [];
    }
    return userActivityFull?.map((activity) => ({
      activityId: activity.activity?.activityId,
      userActivityHealthInsurances: activity.userActivityHealthInsurances.map(
        (healthInsurance) => ({
          healthInsuranceId: healthInsurance.healthInsurance?.healthInsuranceId,
          include: healthInsurance.include
        })
      )
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  getFormattedPhoneNumber(phoneNumber: string) {
    if (!phoneNumber?.length) {
      return '';
    }
    return phoneNumber
      ?.split('+55')[1]
      ?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
  }
}

export default new UserUtil();
