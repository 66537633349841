import { Route } from 'vue-router';

import Store from '@/core/Store';
const MainLayout = () =>
  import(
    /* webpackChunkName: "core-main-layout" */
    '@/core/Components/MainLayout'
  );

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard-main-page" */ '../Pages/Dashboard');
const DashboardDetail = () =>
  import(/* webpackChunkName: "dashboard-detail-page" */ '../Pages/Detail.vue');
const DashboardAdmin = () =>
  import(/* webpackChunkName: "dashboard-admin-page" */ '../Pages/Admin.vue');

import RoutePath from './paths';

const protectedRoute = async (
  to: Route,
  from: Route,
  next: CallableFunction
) => {
  if (to.path === '/auth/login') {
    next();
    return;
  }
  next(await Store.dispatch('auth/check', false));
};

export default [
  {
    path: RoutePath.DASHBOARD_LIST,
    component: MainLayout,
    meta: {
      title: 'Dashboard'
    },
    children: [
      {
        path: RoutePath.DASHBOARD_LIST,
        name: RoutePath.DASHBOARD_LIST,
        beforeEnter: protectedRoute,
        component: DashboardAdmin
      }
    ]
  },
  {
    path: RoutePath.DASHBOARD,
    name: RoutePath.DASHBOARD,
    component: Dashboard,
    beforeEnter: protectedRoute
  },
  {
    name: RoutePath.DASHBOARD_DETAIL_ALL,
    path: RoutePath.DASHBOARD_DETAIL_ALL,
    component: DashboardDetail,
    beforeEnter: protectedRoute
  },
  {
    name: RoutePath.DASHBOARD_DETAIL_ACTIVITY,
    path: RoutePath.DASHBOARD_DETAIL_ACTIVITY,
    component: DashboardDetail,
    beforeEnter: protectedRoute
  }
];
