import { Store } from 'vuex';
import axios from 'axios';

import AllBillsFactory from '@/modules/AllBills/Services';
import CompanyFactory from '@/core/Services/company';
import { AllBillsStore, SearchQuery } from '@types';
import AllBillsUtil from '@/modules/AllBills/Utils';
import { capitalizeSentence } from '@/helpers';

export default {
  async fetch({ commit, state }: Store<AllBillsStore>) {
    commit('SET_BUSY', 'fetch');
    try {
      const {
        search,
        searchCode,
        pagination: { perPage, current_page },
        cancelToken
      } = state;

      const page = current_page;
      const searchString = AllBillsUtil.convertSearchString(search);

      const params = {
        search: searchString,
        searchCode,
        page,
        perPage
      };

      commit('SET_NOT_FOUND');

      if (cancelToken != null) {
        commit('SET_HAVE_CANCELLED', true);
        cancelToken.cancel();
      }
      const source = axios.CancelToken.source();
      commit('SET_CANCEL_TOKEN', source);
      const data = await AllBillsFactory.getBills(params, source.token);

      const {
        content,
        totalPages: pages,
        totalElements: total,
        notFound
      } = data;

      commit('SET_NOT_FOUND', notFound);
      commit(
        'SET_LIST',
        content.map(({ patientName, ...card }) => ({
          ...card,
          patientName: capitalizeSentence(patientName)
        }))
      );

      commit('UPDATE_PAGINATION', {
        total,
        pages
      });
    } catch (e: any) {
      if (!axios.isCancel(e)) {
        commit('SET_ERROR', {
          key: 'allBills',
          value: 'Erro no envio serviço'
        });
      }
    } finally {
      if (state.haveCancelled) {
        commit('SET_HAVE_CANCELLED', false);
      } else {
        commit('SET_BUSY');
        commit('SET_CANCEL_TOKEN', null);
      }
    }
  },
  setSearch(
    { commit }: Store<AllBillsStore>,
    searchQueries: SearchQuery[] = []
  ) {
    commit('SET_SEARCH', searchQueries[0]?.searchString ?? '');
    commit('SET_SEARCH_CODE', searchQueries[0]?.searchCode ?? '');
  },
  setPage({ commit }: Store<AllBillsStore>, pageNumber = 1) {
    commit('SET_PAGE', pageNumber);
  },
  async getSearchCodeOptions({ commit }: Store<AllBillsStore>) {
    const result = await CompanyFactory.getBillAdvancedSearches();
    commit('SET_SEARCH_CODE_OPTIONS', result);
  },
  clearList({ commit }: Store<AllBillsStore>) {
    commit('SET_LIST');
  },
  removeItemNotFound(
    { commit }: Store<AllBillsStore>,
    { searchCode, searchValue }: { searchCode: string; searchValue: string }
  ) {
    commit('REMOVE_SEARCH_ITEM', searchValue);
    commit('REMOVE_NOT_FOUND_ITEM', { searchCode, searchValue });
  }
};
