export enum AutorityType {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_USER_MANAGER = 'ROLE_USER_MANAGER',
  ROLE_APPLICATION = 'ROLE_APPLICATION',
  ROLE_REPORTS_VIEWER = 'ROLE_REPORTS_VIEWER',
  ROLE_FINANCIAL_VIEWER = 'ROLE_FINANCIAL_VIEWER',
  ROLE_BILLINGS_VIEWER = 'ROLE_BILLINGS_VIEWER',
  ROLE_INTERNAL_VIEWER = 'ROLE_INTERNAL_VIEWER',
  ROLE_EXTERNAL_VIEWER = 'ROLE_EXTERNAL_VIEWER'
}

export interface IUserAuthority {
  authority: AutorityType;
  companyId: number;
  userAuthorityId: number;
}
