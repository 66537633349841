import { pipe, useBusy, useErrors } from '@/helpers';

import { state } from './all-bills.state';
import actions from './all-bills.actions';
import getters from './all-bills.getters';
import mutations from './all-bills.mutations';

const useHelpers = pipe(useBusy, useErrors);
/**
 * AllBills store is namespaced.
 */
const namespaced = true;

export default useHelpers({
  state,
  actions,
  getters,
  mutations,
  namespaced
});
