import { DashboardService } from '@/modules/Dashboard/Services';
import { Store } from 'vuex';
import { capitalizeSentence } from '@/helpers';
import IDashboardStore from '../Interfaces/store';

const SERVICE_SENT_ERROR_MESSAGE = 'Erro no envio serviço';
const SERVICE_RESPONSE_ERROR_MESSAGE = 'Erro na resposta do serviço';

export default {
  async complete({ commit }: Store<IDashboardStore>, dashboardId: number) {
    commit('SET_BUSY', 'complete');
    try {
      const result = await DashboardService.getCompleteDashboard(dashboardId);
      commit('SET_COMPLETE', result);
      commit('SET_GROUP_ID', result.groups[0].activityGroupId);

      commit('SET_BUSY', 'groupById');
      const activities = await DashboardService.getGroupsDashboardById(
        dashboardId,
        result.groups[0].activityGroupId
      );
      commit('SET_ACTIVITIES', activities);
    } catch (e: any) {
      commit('SET_ERROR', {
        key: 'complete',
        value: SERVICE_RESPONSE_ERROR_MESSAGE
      });
      commit('SET_COMPLETE');
    } finally {
      commit('SET_BUSY');
    }
  },

  async getDashboardsList({ commit }: Store<IDashboardStore>) {
    commit('SET_BUSY', 'dashboards_list');
    try {
      const result = await DashboardService.getAllDashboards();
      commit('SET_DASHBOARDS_LIST', result);
    } catch (e: any) {
      commit('SET_ERROR', {
        key: 'dashboards_list',
        value: SERVICE_RESPONSE_ERROR_MESSAGE
      });
      commit('SET_DASHBOARDS_LIST');
    } finally {
      commit('SET_BUSY');
    }
  },

  async delete({ commit }: Store<IDashboardStore>, dashboardId: number) {
    commit('SET_BUSY', 'delete');

    try {
      await DashboardService.deleteDashboard(dashboardId);
    } catch (e: any) {
      commit('SET_ERROR', {
        key: 'delete',
        value: SERVICE_SENT_ERROR_MESSAGE
      });
    } finally {
      commit('SET_BUSY');
    }
  },

  async edit(
    { commit }: Store<IDashboardStore>,
    {
      billTypes,
      dashboardId,
      name,
      healthInsuranceIds,
      defaultCompetenceId
    }: {
      billTypes: string[];
      dashboardId: number;
      name: string;
      healthInsuranceIds: number[];
      defaultCompetenceId: number;
    }
  ) {
    commit('SET_BUSY', 'edit');

    const body = {
      defaultCompetenceId,
      billTypes,
      name,
      healthInsuranceIds
    };
    try {
      const result = await DashboardService.updateDashboard(dashboardId, body);
      commit('SET_EDITED', result);
    } catch (e: any) {
      commit('SET_ERROR', {
        key: 'edit',
        value: SERVICE_SENT_ERROR_MESSAGE
      });
    } finally {
      commit('SET_BUSY');
    }
  },

  async send(
    { commit }: Store<IDashboardStore>,
    {
      billTypes,
      name,
      healthInsuranceIds,
      defaultCompetenceId
    }: {
      billTypes: string[];
      name: string;
      healthInsuranceIds: number[];
      defaultCompetenceId: number;
    }
  ) {
    commit('SET_BUSY', 'send');

    const body = {
      defaultCompetenceId,
      billTypes,
      name,
      healthInsuranceIds
    };
    try {
      const result = await DashboardService.createDashboard(body);
      commit('SET_SENT', result);
    } catch (e: any) {
      commit('SET_ERROR', {
        key: 'send',
        value: SERVICE_SENT_ERROR_MESSAGE
      });
    } finally {
      commit('SET_BUSY');
    }
  },

  async groups({ commit }: Store<IDashboardStore>, dashboardId: number) {
    commit('SET_BUSY', 'groups');
    try {
      const result = await DashboardService.getGroupsDashboard(dashboardId);
      commit('SET_GROUPS', result);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  async groupById({ commit, state }: Store<IDashboardStore>, groupId: number) {
    commit('SET_BUSY', 'groupById');
    try {
      const { dashboardId } = state;
      const result = await DashboardService.getGroupsDashboardById(
        dashboardId,
        groupId
      );
      commit('SET_ACTIVITIES', result);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  setGroupId({ commit }: Store<IDashboardStore>, groupId: number) {
    commit('SET_GROUP_ID', groupId);
  },

  async header({ commit }: Store<IDashboardStore>, dashboardId: number) {
    commit('SET_BUSY', 'header');
    try {
      const result = await DashboardService.getHeaderDashboard(dashboardId);
      commit('SET_HEADER', result);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  /**
   * @deprecated
   */
  async overall({ commit }: Store<IDashboardStore>, dashboardId: number) {
    commit('SET_BUSY', 'overall');
    try {
      const result = await DashboardService.getOverallDashboard(dashboardId);
      commit('SET_OVERALL', result);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  async overview({ commit }: Store<IDashboardStore>, dashboardId: number) {
    commit('SET_BUSY', 'overview');
    try {
      const result = await DashboardService.getOverviewDashboard(dashboardId);
      commit('SET_OVERVIEW', result);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  async billActivities(
    { commit, state }: Store<IDashboardStore>,
    {
      activityGroupId,
      activityId
    }: {
      activityGroupId: number;
      activityId: number;
    }
  ) {
    commit('SET_BUSY', 'billActivities');
    try {
      const {
        dashboardId,
        direction,
        column,
        billStatusSet,
        pagination: { perPage, current_page }
      } = state;

      const auxActivityGroupId = activityGroupId != 0 ? activityGroupId : null;
      const auxActivityId = activityId != 0 ? activityId : null;

      const page = current_page;
      const params = {
        column,
        direction,
        page,
        perPage,
        activityId: auxActivityId,
        activityGroupId: auxActivityGroupId,
        billStatusSet: billStatusSet.toString()
      };
      const {
        content,
        totalPages: pages,
        totalElements: total,
        valueProduced
      } = await DashboardService.getBillActivitiesDashboard(
        dashboardId,
        params
      );

      commit('SET_VALUE_PRODUCED', valueProduced);
      commit('UPDATE_PAGINATION', {
        total,
        pages
      });
      commit(
        'SET_BILL_ACTIVITIES',
        content.map(({ patient, ...activity }: any) => ({
          ...activity,
          patient: {
            ...patient,
            name: capitalizeSentence(patient.name)
          }
        }))
      );
    } catch (e: any) {
      throw new Error(e);
    } finally {
      commit('SET_BUSY');
    }
  },
  setCarouselTransitionTime({ commit }: Store<IDashboardStore>, time: number) {
    commit('SET_CAROUSEL_TRANSITION_TIME', time * 1000);
  },
  setDashboardId({ commit }: Store<IDashboardStore>, id: number) {
    commit('SET_DASHBOARD_ID', id);
  },
  setPage({ commit }: Store<IDashboardStore>, page: number) {
    commit('SET_PAGE', page);
  },
  setActivityId({ commit }: Store<IDashboardStore>, activityId: number) {
    commit('SET_ACTIVITY_ID', activityId);
  },
  setColumn({ commit }: Store<IDashboardStore>, column: number) {
    commit('SET_COLUMN', column);
  },
  setDirection({ commit }: Store<IDashboardStore>, direction: string) {
    commit('SET_DIRECTION', direction);
  },
  setbillStatusSet({ commit }: Store<IDashboardStore>, billStatusSet: any) {
    commit('SET_PAY_STATUS_SET', billStatusSet);
  }
};
