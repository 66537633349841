import IUser from '@/modules/Settings/Modules/User/Interfaces/user';
import axios from '@/axios';

import IAuthStore from '../Interfaces/store';
import { getDefaultState } from './auth.state';

export default {
  SET_COMPANY_ID(state: IAuthStore, company_id: string) {
    state.company_id = company_id;

    window.localStorage.setItem('company_id', company_id);

    axios.defaults.headers.common['x-company-id'] = state.company_id;
  },

  SET_TOKEN(state: IAuthStore, token: string) {
    /**
     * Reset status to "not checked".
     */
    state.status = null;
    state.token = token;

    window.localStorage.setItem('token', token);

    axios.defaults.headers.common.Authorization = state.token;
  },

  SET_STATUS(state: IAuthStore, status: any) {
    state.status = status;
  },

  SET_USER(state: IAuthStore, user: IUser) {
    state.user = user;
  },

  SET_PHONE_NUMBER(state: IAuthStore, phoneNumber: string) {
    state.phoneNumber = phoneNumber;
  },

  SET_EMAIL(state: IAuthStore, email: string) {
    state.email = email;
  },

  SET_USERNAME(state: IAuthStore, username: string) {
    state.username = username;
  },

  SET_NAME(state: IAuthStore, name: string) {
    state.name = name;
  },

  SET_CODE(state: IAuthStore, code: string) {
    state.code = code;
  },

  SET_REMAINING_LOGIN_ATTEMPS(
    state: IAuthStore,
    remainingLoginAttemps: number
  ) {
    state.remainingLoginAttemps = remainingLoginAttemps;
  },

  SET_DEVICE_IDENTITY(state: IAuthStore, deviceIdentity: string) {
    state.deviceIdentity = deviceIdentity;
  },

  RESET_STATE(state: IAuthStore) {
    Object.assign(state, getDefaultState());
  }
};
