import IBillActivity from '@/core/Interfaces/bill_activity';
import IBillGroup from '@/core/Interfaces/bill_group';

export interface IBillGroupStore {
  selectedBillActivities: IBillActivity[];
  selectedBillGroups: IBillGroup[];
  indeterminateBillGroupIds: number[];
  removingBillGroupIds: number[];
}

export const getDefaultState: () => IBillGroupStore = () => {
  return {
    selectedBillActivities: [],
    selectedBillGroups: [],
    indeterminateBillGroupIds: [],
    removingBillGroupIds: []
  };
};

export const state = getDefaultState();
