import { pipe, useBusy, useErrors } from '@/helpers';
import { client } from '@/axios';
import AttachmentFactory from '@/core/Services/attachment';
import AttachmentUtil from '@/core/Utils/attachment';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  attachmentsAreInPendency: [],
  attachmentsAreNotInPendency: [],
  uploadStatus: []
});

const theState = getDefaultState();

const getters = {
  uploadPercent: (state) =>
    state.uploadStatus.map((status) => ({
      billId: status.billId,
      percent: (100 * status.uploaded) / status.uploading
    }))
};

const mutations = {
  SET_LISTS(state, { attachmentsAreInPendency, attachmentsAreNotInPendency }) {
    state.attachmentsAreInPendency = attachmentsAreInPendency;
    state.attachmentsAreNotInPendency = attachmentsAreNotInPendency;
  },
  ADD_ARE_IN_PENDENCY(state, payload) {
    payload.forEach((attachment) =>
      state.attachmentsAreInPendency.unshift(attachment)
    );
  },
  ADD_ARE_NOT_IN_PENDENCY(state, payload) {
    payload.forEach((attachment) =>
      state.attachmentsAreNotInPendency.unshift(attachment)
    );
  },
  REMOVE_ARE_IN_PENDENCY(state, attachmentId) {
    const index = state.attachmentsAreInPendency.findIndex(
      (attachment) => attachment.attachmentId === attachmentId
    );
    state.attachmentsAreInPendency.splice(index, 1);
  },
  REMOVE_ARE_NOT_IN_PENDENCY(state, attachmentId) {
    const index = state.attachmentsAreNotInPendency.findIndex(
      (attachment) => attachment.attachmentId === attachmentId
    );
    state.attachmentsAreNotInPendency.splice(index, 1);
  },
  UPDATE_ATTACHMENT(state, payload) {
    const index = state.attachmentsAreNotInPendency.findIndex(
      (attachment) => attachment.attachmentId === payload.attachmentId
    );
    if (index > -1) {
      Object.assign(state.attachmentsAreNotInPendency[index], payload);
      return;
    }

    const indexPendency = state.attachmentsAreInPendency.findIndex(
      (attachment) => attachment.attachmentId === payload.attachmentId
    );
    Object.assign(state.attachmentsAreInPendency[indexPendency], payload);
  },
  SET_UPLOADING(state, { billId, numberOfFiles }) {
    const statusIndex = state.uploadStatus.findIndex(
      (status) => status.billId === billId
    );

    if (statusIndex === -1) {
      state.uploadStatus.push({
        billId,
        uploading: numberOfFiles,
        uploaded: 0
      });
      return;
    }

    state.uploadStatus[statusIndex].uploading = numberOfFiles;
    state.uploadStatus[statusIndex].uploaded = 0;
  },
  SET_UPLOADED(state, { billId, isLastRequest }) {
    const statusIndex = state.uploadStatus.findIndex(
      (status) => status.billId === billId
    );

    if (statusIndex === -1) {
      return;
    }

    if (isLastRequest) {
      state.uploadStatus[statusIndex].uploaded =
        state.uploadStatus[statusIndex].uploading;
      return;
    }

    state.uploadStatus[statusIndex].uploaded++;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async createValidUrlAndPutFiles({ commit }, { files, billId }) {
    if (!files || !files.length) {
      return null;
    }

    const preSignedRequest = {
      billId,
      attachmentPreSignedUrls: files.map((file, index) => ({
        contentType: 'multipart/form-data',
        fileName: AttachmentUtil.slugify(file.name),
        index
      }))
    };
    const {
      data: { attachmentPreSignedUrls }
    } = await AttachmentFactory.createPreSignedUrl(preSignedRequest);

    return files.map(async (file, index) => {
      const { url } = attachmentPreSignedUrls.find(
        (preSigned) => preSigned.index === index
      );

      await client({
        url,
        data: file,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      commit('SET_UPLOADED', { billId, isLastRequest: false });

      return {
        title: file.title,
        description: file.description,
        url
      };
    });
  },
  async getAttachments({ commit }, billId) {
    commit('SET_BUSY', 'get_attachments');
    const { data } = await AttachmentFactory.getAttachments(billId);
    commit('SET_LISTS', data);
    commit('SET_BUSY');
  },
  async uploadAttachments(
    { commit, dispatch },
    { files, billId, billActivityPendencyId }
  ) {
    if (!files.length) {
      return null;
    }
    commit('SET_BUSY', 'upload_attachment');
    try {
      commit('SET_UPLOADING', { billId, numberOfFiles: files.length + 1 });
      const attachmentRequest = {
        billId,
        attachments: []
      };

      if (billActivityPendencyId) {
        attachmentRequest.billActivityPendencyId = billActivityPendencyId;
      }

      const attachmentsArray = await dispatch('createValidUrlAndPutFiles', {
        files,
        billId
      });
      await Promise.all(attachmentsArray).then((result) => {
        attachmentRequest.attachments = result;
      });

      if (!attachmentRequest.attachments.length) {
        commit('SET_BUSY');
        return null;
      }

      const attachmentsResult = await AttachmentFactory.createAttachment(
        attachmentRequest
      );

      commit('SET_UPLOADED', { billId, isLastRequest: true });
      commit('SET_BUSY');
      if (billActivityPendencyId) {
        commit('ADD_ARE_IN_PENDENCY', attachmentsResult.data);
      } else {
        commit('ADD_ARE_NOT_IN_PENDENCY', attachmentsResult.data);
      }

      return attachmentsResult.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteAttachment({ commit }, attachmentId) {
    if (!attachmentId) {
      return;
    }
    try {
      commit('SET_BUSY', 'delete_attachment');
      await AttachmentFactory.deleteAttachment(attachmentId);
      commit('REMOVE_ARE_NOT_IN_PENDENCY', attachmentId);
    } finally {
      commit('SET_BUSY');
    }
  },
  async updateAttachment({ commit }, { attachmentId, params }) {
    if (!attachmentId) {
      return null;
    }
    try {
      commit('SET_BUSY', 'update_attachment');
      const { data } = await AttachmentFactory.updateAttachment(
        attachmentId,
        params
      );
      commit('UPDATE_ATTACHMENT', data);
      return data;
    } finally {
      commit('SET_BUSY');
    }
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
