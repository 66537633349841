import IUserStore from '../Interfaces/store';

export const getDefaultState: () => IUserStore = () => {
  return {
    list: [],

    search: '',

    pagination: {
      total: 0,
      pages: 0,
      perPage: 10,
      current_page: 1
    },

    cancelToken: null,
    haveCancelled: false
  };
};

export const state = getDefaultState();
