import { Store } from 'vuex';
import IBillActivity from '@/core/Interfaces/bill_activity';
import IBillGroup from '@/core/Interfaces/bill_group';
import { IBillGroupStore } from './billGroup.state';

export default {
  selectBillActivities(
    { commit, state }: Store<IBillGroupStore>,
    {
      billActivities,
      billGroupId,
      setIndeterminate
    }: {
      billActivities: IBillActivity[];
      billGroupId: number;
      setIndeterminate: boolean;
    }
  ) {
    if (
      state.selectedBillGroups
        .map((billGroupSelected) => billGroupSelected.billGroupId)
        .includes(billGroupId)
    ) {
      return;
    }
    commit('SELECT_BILL_ACTIVITIES', billActivities);

    if (!setIndeterminate) {
      return;
    }
    commit('SET_INDETERMINATE', billGroupId);
  },

  unselectBillActivities(
    { commit }: Store<IBillGroupStore>,
    {
      billActivities,
      billGroupId,
      unsetIndeterminate
    }: {
      billActivities: IBillActivity[];
      billGroupId: number;
      unsetIndeterminate: boolean;
    }
  ) {
    commit('UNSELECT_BILL_GROUPS', [billGroupId]);
    commit('UNSELECT_BILL_ACTIVITIES', billActivities);

    if (!unsetIndeterminate) {
      return;
    }
    commit('UNSET_INDETERMINATE', billGroupId);
  },

  selectBillGroup(
    { commit }: Store<IBillGroupStore>,
    {
      billActivities,
      billGroup
    }: {
      billActivities: IBillActivity[];
      billGroup: IBillGroup;
    }
  ) {
    commit('SELECT_BILL_GROUPS', [billGroup]);
    commit('UNSET_INDETERMINATE', billGroup.billGroupId);
    commit('UNSELECT_BILL_ACTIVITIES', billActivities);
  },

  unselectBillGroup({ commit }: Store<IBillGroupStore>, billGroupId: number) {
    commit('UNSELECT_BILL_GROUPS', [billGroupId]);
  },

  unselectAll({ commit }: Store<IBillGroupStore>) {
    commit('UNSELECT_ALL');
  },

  toggleBillGroupsSelected(
    { commit }: Store<IBillGroupStore>,
    {
      list,
      isAllChecked
    }: {
      list: IBillGroup[];
      isAllChecked: boolean;
    }
  ) {
    if (isAllChecked) {
      commit(
        'UNSELECT_BILL_GROUPS',
        list.map(({ billGroupId }) => billGroupId)
      );
      return;
    }

    commit('SELECT_BILL_GROUPS', list);
  },

  setIsRemoving({ commit }: Store<IBillGroupStore>, billGroupIds: number[]) {
    commit('SET_REMOVING_BILL_GROUPS', billGroupIds);
  }
};
