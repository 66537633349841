import { pipe, useBusy, useErrors } from '@/helpers';
import NotificationFactory from '@/core/Services/notification';
import NotificationUtil from '@/core/Utils/notification';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  paginateNotifications: {},
  currentNotifications: [],
  currentPage: 0,
  paginateNewNotifications: {},
  newNotifications: [],
  unread: 0,
  lastSeenNotification: -1
});

const theState = getDefaultState();

export const theGetters = {
  notifications: (state) => [
    ...state.newNotifications,
    ...state.currentNotifications
  ],
  countUnread: (state) => state.unread,
  toNotificate: (state) =>
    NotificationUtil.hasToNotificate(
      state.newNotifications,
      state.lastSeenNotification
    ),
  isLastPage: (state) => state.paginateNotifications?.last
};

export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.paginateNotifications = { ...notifications };
    let notificationsArr = NotificationUtil.sortArrayById(
      notifications.content
    );

    if (state.currentNotifications?.length) {
      notificationsArr = notificationsArr.filter(
        (notification) =>
          !NotificationUtil.isNotificationInArray(
            notification,
            state.currentNotifications
          )
      );
    }

    if (state.newNotifications?.length) {
      notificationsArr = notificationsArr.filter(
        (notification) =>
          !NotificationUtil.isNotificationInArray(
            notification,
            state.newNotifications
          )
      );
    }

    state.currentNotifications =
      state.currentNotifications.concat(notificationsArr);
    state.currentPage = notifications.pageable.pageNumber;
  },
  SET_NEW_NOTIFICATIONS(state, notifications) {
    state.paginateNewNotifications = { ...notifications };
    const content = notifications.content.filter(
      (notification) =>
        !NotificationUtil.isNotificationInArray(
          notification,
          state.currentNotifications
        ) &&
        !NotificationUtil.isNotificationInArray(
          notification,
          state.newNotifications
        )
    );

    const newNotifications = NotificationUtil.sortArrayById(content);

    state.newNotifications = newNotifications.concat(state.newNotifications);

    if (state.lastSeenNotification === -1) {
      state.unread = state.paginateNewNotifications.countUnread;
      return;
    }
    state.unread += content.length;
  },
  ADD_NOTIFICATIONS(state, payload) {
    payload.forEach((notification) =>
      state.newNotifications.unshift(notification)
    );
  },
  REMOVE_NOTIFICATIONS(state, notificationsId) {
    const toRemove = [
      ...state.currentNotifications,
      ...state.newNotifications
    ].filter((notification) =>
      NotificationUtil.isNotificationInArrayId(notification, notificationsId)
    );

    state.currentNotifications = state.currentNotifications.filter(
      (notification) =>
        !NotificationUtil.isNotificationInArrayId(notification, notificationsId)
    );

    state.newNotifications = state.newNotifications.filter(
      (notification) =>
        !NotificationUtil.isNotificationInArrayId(notification, notificationsId)
    );

    NotificationUtil.handleUnreaded(state, 'subtract', toRemove.length);
  },
  SET_READED(state, { notificationId, readed }) {
    const action = readed ? 'subtract' : 'sum';
    const index = state.currentNotifications.findIndex(
      (notification) => notification.notificationId === notificationId
    );
    if (index !== -1) {
      if (state.currentNotifications[index].readed === readed) {
        return;
      }
      state.currentNotifications[index].readed = readed;
      NotificationUtil.handleUnreaded(state, action, 1);
      return;
    }

    const newIndex = state.newNotifications.findIndex(
      (notification) => notification.notificationId === notificationId
    );
    if (newIndex === -1) {
      return;
    }

    if (state.newNotifications[newIndex].readed === readed) {
      return;
    }
    state.newNotifications[newIndex].readed = readed;
    NotificationUtil.handleUnreaded(state, action, 1);
  },
  SET_LAST_SEEN(state, notificationId) {
    if (state.lastSeenNotification > notificationId) {
      return;
    }
    state.lastSeenNotification = notificationId;
  },
  CLEAR_UNREAD(state) {
    state.unread = 0;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export const actions = {
  async getNotifications({ commit, state }) {
    if (state.paginateNotifications?.last) {
      return;
    }

    const params = { page: state.currentPage + 2 };

    commit('SET_BUSY', 'get_notifications');
    const notifications = await NotificationFactory.getNotifications(params);
    commit('SET_NOTIFICATIONS', notifications);
    commit('SET_BUSY');
  },
  async getNewNotifications({ commit, state, dispatch, getters }) {
    commit('SET_BUSY', 'get_new_notifications');
    const params = { lastNotificationId: state.lastSeenNotification };
    const notifications = await NotificationFactory.getNotifications(params);
    commit('SET_NEW_NOTIFICATIONS', notifications);
    commit('SET_BUSY');

    setTimeout(() => {
      dispatch('setLastSeen', getters.notifications[0]?.notificationId);
    }, NotificationUtil.timeToHideFloating);
  },
  async setReaded({ commit }, { notificationId, readed }) {
    commit('SET_BUSY', 'set_readed');
    await NotificationFactory.setReaded(notificationId, readed);
    commit('SET_READED', { notificationId, readed });
    commit('SET_BUSY');
  },
  async deleteNotifications({ commit }, notificationsId) {
    commit('SET_BUSY', 'delete_notifications');
    commit(
      'REMOVE_NOTIFICATIONS',
      notificationsId.split(',').map((id) => +id)
    );
    await NotificationFactory.deleteNotifications(notificationsId);
    commit('SET_BUSY');
  },
  async createNotifications({ commit }, notifications) {
    commit('SET_BUSY', 'create_notifications');
    const notificationsCreated = await NotificationFactory.createNotifications(
      notifications
    );
    commit('ADD_NOTIFICATIONS', notificationsCreated);
    commit('SET_BUSY');
  },
  setLastSeen({ commit }, notificationId) {
    if (notificationId === undefined) {
      commit('SET_LAST_SEEN', -1);
      return;
    }
    commit('SET_LAST_SEEN', notificationId);
  },
  clearUnread({ commit }) {
    commit('CLEAR_UNREAD');
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters: theGetters,
  mutations,
  namespaced
});
