import { pipe, useBusy, useErrors } from '@/helpers';
import CompetencesFactory from '@/core/Services/competence';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  competences: []
});

const theState = getDefaultState();

const theGetters = {
  select: (state) =>
    state.competences.map(({ competenceId: value, date }) => ({
      value,
      date
    })),

  selectSorted: (state, getters) =>
    getters.select.slice().sort((a, b) => new Date(a.date) - new Date(b.date))
};

const mutations = {
  SET_COMPETENCES(state, competences) {
    state.competences = competences;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async competence({ commit }) {
    commit('SET_BUSY', 'competence');
    try {
      const { data } = await CompetencesFactory.getCompetences();

      commit('SET_COMPETENCES', data);
    } catch (e) {
      commit('SET_ERROR', {
        key: 'competence',
        value: 'Erro na resposta do serviço'
      });
      commit('SET_COMPETENCES');
    } finally {
      commit('SET_BUSY');
    }
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters: theGetters,
  mutations,
  namespaced
});
