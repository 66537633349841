import Service from '@/core/Services/service';

class Factory extends Service {
  getCurrentUser() {
    return this.get('users/current');
  }

  setLogin(params) {
    return this.post('/login', params, { withCredentials: true });
  }

  setRecover(username) {
    return this.post(`users/${username}/forgot_password`);
  }

  setConfirm(username, token) {
    return this.post(`users/${username}/token/${token}`);
  }

  setReset(username, params) {
    return this.post(`users/${username}/reset_password`, params);
  }

  submitTwoFactorCode(identity) {
    return this.post('/users/auth-check/send', {
      identity,
      sendSms: true,
      sendEmail: true
    });
  }

  checkTwoFactor(identity, code) {
    return this.post('/users/auth-check/verify', { identity, code });
  }
}

export default new Factory();
