import { Store } from 'vuex';

const namespaced = true;

type SupportActionsState = typeof theState;

const getDefaultState = () => ({
  hideShowSupportActions: false,
  showBillActivityIdsInCards: false
});

const theState = getDefaultState();

const getters = {
  canShowSupportActions: (
    state: SupportActionsState,
    _getters: any,
    _rootState: any,
    rootGetters: any
  ) => rootGetters['auth/isPegCompany'] && !state.hideShowSupportActions,
  showBillActivityIdsInCards: (state: SupportActionsState) =>
    state.showBillActivityIdsInCards
};

const mutations = {
  SET_HIDE_SHOW_SUPPORT_ACTIONS(
    state: SupportActionsState,
    hideShowSupportActions: boolean
  ) {
    state.hideShowSupportActions = hideShowSupportActions;
  },
  SET_SHOW_BILL_ACTIVITY_IDS_IN_CARDS(
    state: SupportActionsState,
    showBillActivityIdsInCards: boolean
  ) {
    state.showBillActivityIdsInCards = showBillActivityIdsInCards;
  },
  RESET_STATE(state: SupportActionsState) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  toggleHideShowSupportActions({ commit, state }: Store<SupportActionsState>) {
    commit('SET_HIDE_SHOW_SUPPORT_ACTIONS', !state.hideShowSupportActions);
  },
  toggleShowBillActivityIdsInCards({
    commit,
    state
  }: Store<SupportActionsState>) {
    commit(
      'SET_SHOW_BILL_ACTIVITY_IDS_IN_CARDS',
      !state.showBillActivityIdsInCards
    );
  }
};

export default {
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
};
