import UserUtil from '@/modules/Settings/Modules/User/Utils';

export const getDefaultState = () => ({
  activity: -1,
  send_to: null,
  activities: [],
  is_valid_to_forward: false,

  errors: [],
  list: [],
  selected: [],
  remove: [],
  listBackward: [],
  notFound: null,

  status: {
    warn: 0,
    danger: 0,
    success: 0,
    total: 0,
    totalAmount: 0
  },

  search: '',
  friendlyBillActivityLotId: '',
  forwarded: false,

  searchCode: '',
  searchCodeOptions: [],

  pagination: {
    total: 0,
    pages: 0,
    perPage: UserUtil.limitPerPage,
    current_page: 1
  },

  filter: '',
  responsibleFilter: '',
  orderFilter: '',
  timeRangeFilter: '',
  timeRangeTypeFilter: '',
  flagFilter: '',
  typeFilter: '',

  cancelToken: null,
  haveCancelled: false
});

export const state = getDefaultState();
