import { Route } from 'vue-router';

import Store from '@/core/Store';
import RoutePath from '@/route-path';
const MainLayout = () =>
  import(
    /* webpackChunkName: "core-main-layout" */
    '@/core/Components/MainLayout'
  );

const Settings = () =>
  import(/* webpackChunkName: "settings-main-page" */ '../Pages/Settings');
const SettingsUser = () =>
  import(
    /* webpackChunkName: "settings-user-main-page" */
    '@/modules/Settings/Modules/User/Pages/User'
  );
const SettingsUserDetail = () =>
  import(
    /* webpackChunkName: "settings-user-detail-page" */
    '@/modules/Settings/Modules/User/Pages/UserDetail'
  );
const SettingsHealthInsurances = () =>
  import(
    /* webpackChunkName: "settings-health-insurances-main-page" */
    '../Modules/HealthInsurances/Pages/HealthInsurances'
  );
const SettingsMoveRules = () =>
  import(
    /* webpackChunkName: "settings-move-rules-main-page" */
    '../Modules/MoveRules/Pages/MoveRules'
  );
const SettingsMoveRulesCreate = () =>
  import(
    /* webpackChunkName: "settings-move-rules-create-page" */
    '../Modules/MoveRules/Pages/MoveRulesCreate'
  );
const SettingsSectors = () =>
  import(
    /* webpackChunkName: "settings-sectors-page" */
    '../Modules/Sectors/Pages/Sectors'
  );

const protectedRoute = async (
  to: Route,
  from: Route,
  next: CallableFunction
) => {
  if (to.path === RoutePath.AUTH_LOGIN) {
    next();
    return;
  }
  const result = await Store.dispatch('auth/check', false);

  const isAdmin = Store.getters['auth/isAdmin'];
  const isPegCompany = Store.getters['auth/isPegCompany'];

  if (!Store.getters['auth/canCurrentUserEditUsers']) {
    next({
      name: RoutePath.ACTIVITY
    });
  }

  if (to.path === RoutePath.SETTINGS_HEALTH_INSURANCES && !isAdmin) {
    next({
      name: RoutePath.ACTIVITY
    });
  }

  if (
    (to.path === RoutePath.SETTINGS_MOVE_RULES ||
      to.path === RoutePath.SETTINGS_MOVE_RULES_CREATE) &&
    !(isAdmin && isPegCompany)
  ) {
    next({
      name: RoutePath.ACTIVITY
    });
  }

  next(result);
};

export default [
  {
    path: RoutePath.SETTINGS,
    component: MainLayout,
    beforeEnter: protectedRoute,
    meta: {
      search: {
        source: 'user'
      },
      title: 'Configurações'
    },
    children: [
      {
        path: RoutePath.SETTINGS,
        name: RoutePath.SETTINGS,
        component: Settings,
        props: true,
        redirect: RoutePath.SETTINGS_USER,
        children: [
          {
            path: RoutePath.SETTINGS_HEALTH_INSURANCES,
            name: RoutePath.SETTINGS_HEALTH_INSURANCES,
            component: SettingsHealthInsurances,
            props: true
          },
          {
            path: RoutePath.SETTINGS_USER,
            name: RoutePath.SETTINGS_USER,
            component: SettingsUser,
            props: true
          },
          {
            path: RoutePath.SETTINGS_USER_ACTIVITY,
            name: RoutePath.SETTINGS_USER_ACTIVITY,
            beforeEnter: protectedRoute,
            component: SettingsUser
          },
          {
            path: RoutePath.SETTINGS_USER_CREATE,
            name: RoutePath.SETTINGS_USER_CREATE,
            beforeEnter: protectedRoute,
            component: SettingsUserDetail
          },
          {
            path: RoutePath.SETTINGS_USER_DETAIL,
            name: RoutePath.SETTINGS_USER_DETAIL,
            beforeEnter: protectedRoute,
            component: SettingsUserDetail
          },
          {
            path: RoutePath.SETTINGS_MOVE_RULES,
            name: RoutePath.SETTINGS_MOVE_RULES,
            beforeEnter: protectedRoute,
            component: SettingsMoveRules
          },
          {
            path: RoutePath.SETTINGS_MOVE_RULES_CREATE,
            name: RoutePath.SETTINGS_MOVE_RULES_CREATE,
            beforeAll: protectedRoute,
            component: SettingsMoveRulesCreate
          },
          {
            path: RoutePath.SETTINGS_SECTORS,
            name: RoutePath.SETTINGS_SECTORS,
            beforeAll: protectedRoute,
            component: SettingsSectors
          }
        ]
      }
    ]
  }
];
