import IDashboardStore from '../Interfaces/store';

/**
 * Transform to select.
 */
const transform = ({
  sectorName,
  name: sublabel,
  activityId: value
}: {
  sectorName: any;
  name: any;
  activityId: any;
}) => ({
  value,
  sublabel,
  label: sectorName
});

export default {
  /**
   * Return all ids and names of groups.
   */
  groups_select: (state: IDashboardStore) =>
    state.groups.map(({ activityGroupId, name }) => ({
      value: activityGroupId,
      label: name
    })),

  /**
   * Return all ids, names and sectorName of activities.
   */
  activities_select: (state: IDashboardStore) =>
    state.activities.map(transform),

  current_group: (state: IDashboardStore) => state.groupId,

  carousel_transition_time: (state: IDashboardStore) =>
    state.carouselTransitionTime,

  complete: (state: IDashboardStore) => state.complete,

  activities: (state: IDashboardStore) => state.activities,

  errors: (state: IDashboardStore) => state.errors,

  id: (state: IDashboardStore) => state.dashboardId,

  groupId: (state: IDashboardStore) => state.groupId,
  activityId: (state: IDashboardStore) => state.activityId,
  column: (state: IDashboardStore) => state.column,
  direction: (state: IDashboardStore) => state.direction,
  dashboardId: (state: IDashboardStore) => state.dashboardId,
  valueProduced: (state: IDashboardStore) => state.valueProduced,
  billStatusSet: (state: IDashboardStore) => state.billStatusSet,
  dashboards_list: (state: IDashboardStore) => state.dashboards_list,
  sent: (state: IDashboardStore) => state.sent,
  edited: (state: IDashboardStore) => state.edited
};
