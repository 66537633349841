import { Route } from 'vue-router';

import Store from '@/core/Store';
import RoutePath from '@/route-path';

const MainLayout = () =>
  import(
    /* webpackChunkName: "core-main-layout" */
    '@/core/Components/MainLayout'
  );
const BillMain = () =>
  import(
    /* webpackChunkName: "bill-main-page" */
    '@/modules/Bill/Pages/BillMain'
  );
const BillList = () =>
  import(
    /* webpackChunkName: "bill-list-page" */
    '@/modules/Bill/Pages/BillList'
  );
const BillGroup = () =>
  import(
    /* webpackChunkName: "bill-group-page" */
    '@/modules/Bill/Pages/BillGroup'
  );
const BillActivitiesFlow = () =>
  import(
    /* webpackChunkName: "bill-activities-flow-page" */
    '@/modules/Bill/Pages/BillActivitiesFlow'
  );
const BillDocumentFlow = () =>
  import(
    /* webpackChunkName: "bill-documents-flow-page" */
    '@/modules/Bill/Pages/BillDocumentFlow'
  );
const BillAttachments = () =>
  import(
    /* webpackChunkName: "bill-attachments-page" */
    '@/modules/Bill/Pages/BillAttachments.vue'
  );
const BillComments = () =>
  import(
    /* webpackChunkName: "bill-comments-page" */
    '@/modules/Bill/Pages/BillComments'
  );

const MyActivities = () =>
  import(
    /* webpackChunkName: "my-activities-main-page" */
    '../Pages/MyActivities'
  );

const protectedRoute = async (
  to: Route,
  _from: Route,
  next: CallableFunction
) => {
  if (to.path === RoutePath.AUTH_LOGIN) {
    next();
    return;
  }
  const result = await Store.dispatch('auth/check', false);

  if (!Store.getters['auth/canCurrentUserSeeActivities']) {
    next({
      name: RoutePath.ALL_BILLS
    });
    return;
  }
  next(result);
};

export default [
  {
    path: RoutePath.MAIN,
    component: MainLayout,
    redirect: RoutePath.ACTIVITY,
    beforeEnter: protectedRoute,
    meta: {
      search: {
        source: 'activity'
      },
      title: 'Minhas Atividades'
    },
    children: [
      {
        props: true,
        path: RoutePath.ACTIVITY,
        name: RoutePath.ACTIVITY,
        component: MyActivities
      },
      {
        path: RoutePath.ACTIVITY_BILL,
        beforeEnter: protectedRoute,
        props: true,
        component: BillMain,
        meta: {
          title: 'Detalhes'
        },
        children: [
          {
            path: RoutePath.ACTIVITY_BILL,
            name: RoutePath.ACTIVITY_BILL,
            component: BillList,
            props: true
          },
          {
            path: RoutePath.ACTIVITY_BILL_ACTIVITIES_FLOW,
            name: RoutePath.ACTIVITY_BILL_ACTIVITIES_FLOW,
            component: BillActivitiesFlow
          },
          {
            path: RoutePath.ACTIVITY_BILL_ATTACHMENTS,
            name: RoutePath.ACTIVITY_BILL_ATTACHMENTS,
            component: BillAttachments
          },
          {
            path: RoutePath.ACTIVITY_BILL_COMMENTS,
            name: RoutePath.ACTIVITY_BILL_COMMENTS,
            component: BillComments
          },
          {
            path: RoutePath.ACTIVITY_BILL_DOCUMENTS_FLOW,
            name: RoutePath.ACTIVITY_BILL_DOCUMENTS_FLOW,
            component: BillDocumentFlow
          }
        ]
      },
      {
        path: RoutePath.ACTIVITY_GROUP,
        component: MainLayout,
        meta: {
          title: 'Ciclo de atendimentos'
        },
        children: [
          {
            props: true,
            path: RoutePath.ACTIVITY_GROUP,
            name: RoutePath.ACTIVITY_GROUP,
            beforeEnter: protectedRoute,
            component: BillGroup
          },
          {
            path: RoutePath.ACTIVITY_GROUP_BILL,
            beforeEnter: protectedRoute,
            props: true,
            component: BillMain,
            meta: {
              title: 'Detalhes'
            },
            children: [
              {
                path: RoutePath.ACTIVITY_GROUP_BILL,
                name: RoutePath.ACTIVITY_GROUP_BILL,
                component: BillList,
                props: true
              },
              {
                path: RoutePath.ACTIVITY_GROUP_BILL_ACTIVITIES_FLOW,
                name: RoutePath.ACTIVITY_GROUP_BILL_ACTIVITIES_FLOW,
                component: BillActivitiesFlow
              },
              {
                path: RoutePath.ACTIVITY_GROUP_BILL_ATTACHMENTS,
                name: RoutePath.ACTIVITY_GROUP_BILL_ATTACHMENTS,
                component: BillAttachments
              },
              {
                path: RoutePath.ACTIVITY_GROUP_BILL_COMMENTS,
                name: RoutePath.ACTIVITY_GROUP_BILL_COMMENTS,
                component: BillComments
              },
              {
                path: RoutePath.ACTIVITY_GROUP_BILL_DOCUMENTS_FLOW,
                name: RoutePath.ACTIVITY_GROUP_BILL_DOCUMENTS_FLOW,
                component: BillDocumentFlow
              }
            ]
          }
        ]
      }
    ]
  }
];
