import { pipe, useBusy, useErrors } from '@/helpers';
import Axios from '@/axios';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  bill: -1,
  list: []
});

const theState = getDefaultState();

const mutations = {
  SET_BILL(state, bill) {
    state.bill = bill;
  },

  SET_LIST(state, list) {
    state.list = list;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async fetch({ commit }, id) {
    commit('SET_BUSY', 'fetch');

    try {
      const {
        data: { history },
        status
      } = await Axios.get(`bills/${id}/history`);

      if (status !== 200 && status !== 201) {
        throw new Error();
      }

      commit('SET_BILL', id);
      commit('SET_LIST', history || []);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('SET_BUSY');
    }
    return null;
  }
};

export default useHelpers({
  state: theState,
  actions,
  mutations,
  namespaced
});
