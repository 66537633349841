import DashboardUtil from '@/modules/Dashboard/Utils';
import IDashboardStore from '../Interfaces/store';

export const getDefaultState: () => IDashboardStore = () => {
  return {
    activities: [],
    complete: null,
    dashboardId: null,
    groupId: null,
    activityId: null,
    groups: [],
    header: null,
    overall: null,
    overview: null,
    sent: null,
    edited: null,
    dashboards_list: {},
    pagination: {
      total: 0,
      pages: 0,
      perPage: 20,
      current_page: 1
    },
    direction: '',
    bill_activities: [],
    column: '',
    billStatusSet: [],
    valueProduced: 0,
    carouselTransitionTime: DashboardUtil.autoplayCarouselTime,
    errors: null
  };
};

export const state = getDefaultState();
