import Vue from 'vue';

const upperFirst = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
const upperFirstAllWords = (string) => {
  const arrayFromString = string.split(' ');
  const arrayUppercasedFirst = arrayFromString.map((word) => upperFirst(word));
  return arrayUppercasedFirst.join(' ');
};

export const EventBus = new Vue();

/**
 * Capitalize every word of sentence.
 */
export const capitalizeSentence = (string) =>
  upperFirstAllWords(string.toLowerCase());

/**
 * Capitalize the first letter of sentence and lowercase the remainder
 */
export const capitalizeFirstLetter = (string) =>
  upperFirst(string.toLowerCase());

/**
 * Resolve a promise after ms.
 */
export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

/**
 * Pipe functions.
 */
export const pipe =
  (...functions) =>
  (target) =>
    functions.reduce((value, fn) => fn(value), target); /**

* Format a number to REAL.
*/
export const format = (number, decimals = false) =>
  number.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimals ? 0 : 2
  });

/**
 * Discover percentage between two values.
 */
export const percentage = (firstValue, secondValue, isFixed) => {
  const decrease =
    firstValue - (secondValue > firstValue ? firstValue : secondValue);
  return isFixed
    ? 100 - ((decrease / firstValue) * 100).toFixed(0)
    : 100 - (decrease / firstValue) * 100;
};

export const flexiblePercentage = (firstValue, secondValue, isFixed) => {
  if (!firstValue) {
    return 0;
  }
  const decrease = firstValue - secondValue;
  return isFixed
    ? 100 - ((decrease / firstValue) * 100).toFixed(0)
    : 100 - (decrease / firstValue) * 100;
};

export const exactPercentage = (totalValue, partialValue) =>
  (100 * partialValue) / totalValue;

/**
 * Handle outside click.
 */
export const useOutsideClick = (
  instance,
  ref,
  callback,
  isVueComponent = false,
  onlyMouseDown = false
) => {
  const handler = (event) => {
    if (!instance.$refs || !instance.$refs[ref]) {
      return;
    }
    const elem = isVueComponent ? instance.$refs[ref].$el : instance.$refs[ref];
    if (elem == event.target || elem.contains(event.target)) {
      return;
    }

    callback();
  };

  document.body.addEventListener(
    onlyMouseDown ? 'mousedown' : 'click',
    handler,
    true
  );

  instance.$once('hook:destroyed', () =>
    document.body.removeEventListener(
      onlyMouseDown ? 'mousedown' : 'click',
      handler,
      true
    )
  );
};

/**
 * Install errors in store.
 */
export const useErrors = (
  { state, getters, mutations, ...rest },
  base = {}
) => ({
  ...rest,

  state: {
    ...state,

    /**
     * Add errors to state.
     */
    errors: base
  },

  mutations: {
    ...mutations,

    /**
     * @todo check how validation errors are returned.
     */
    SET_ERRORS(state, errors = {}) {
      state.errors = errors;
    },

    /**
     * Set a error by key.
     */
    SET_ERROR(state, { key, value = '' }) {
      state.errors[key] = value;
    }
  },

  getters: {
    ...getters,

    /**
     * Get a error by key.
     */
    error: (state) => (key) => state.errors[key]
  }
});

/**
 * Install busy in store.
 */
export const useBusy = ({ state, getters, mutations, ...rest }, base = {}) => ({
  ...rest,

  state: {
    ...state,

    /**
     * Add busy to state.
     */
    busy: { ...base }
  },

  mutations: {
    ...mutations,

    /**
     * Set busy state by key or reset them all.
     */
    SET_BUSY(state, key) {
      /**
       * If key is defined, we set it to true.
       */
      if (typeof key === 'string') {
        return Vue.set(state.busy, key, true);
      }

      /**
       * If not, we reset the busy state.
       */
      state.busy = { ...base };
    }
  },

  getters: {
    ...getters,

    /**
     * Get busy state by key.
     */
    busy: (state) => (key) => !!state.busy[key]
  }
});

/**
 * Truncate a string.
 */
export const truncate = (string, length) =>
  string.length > length + 3 ? `${string.slice(0, length)}...` : string;

/**
 * Map number to scale.
 *
 * https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
 */
export const scale = (num, in_min, in_max, out_min, out_max) =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;

export const translateGmtFromDate = (date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export const humanizeDate = (date, gmt = true, complete = false) => {
  const shifted = gmt ? translateGmtFromDate(date) : new Date(date);
  const localized = shifted.toLocaleDateString('pt-BR', {
    day: '2-digit',
    hour: '2-digit',
    year: complete ? 'numeric' : '2-digit',
    month: '2-digit',
    minute: '2-digit'
  });

  return localized.replace(' ', ' às ');
};

// eslint-disable-next-line no-restricted-globals
export const isValidDate = (date) =>
  date instanceof Date && !isNaN(date.getTime());

export const validHumanizedDate = (date, gmt = true, complete = false) => {
  if (!date) {
    return null;
  }

  const dateConverted = new Date(date);
  if (!isValidDate(dateConverted)) {
    return null;
  }

  return humanizeDate(dateConverted, gmt, complete);
};

export const clock = (date, gmt = true) => {
  const shifted = gmt ? translateGmtFromDate(date) : new Date(date);
  return shifted
    .toLocaleDateString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit'
    })
    .split(' ')
    .pop();
};

export const shortDate = (date, year = false, complete = false, gmt = true) => {
  const shifted = gmt
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    : new Date(date);
  const options = {
    day: '2-digit',
    month: '2-digit'
  };

  if (year) {
    options.year = complete ? 'numeric' : '2-digit';
  }
  return shifted.toLocaleDateString('pt-BR', options);
};

export const defaultDate = (date) => {
  const localized = date.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
    month: 'long',
    year: 'numeric'
  });

  return upperFirst(localized.replace(' de ', '/'));
};

export const monthlyDate = (date) => {
  return date.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
    month: '2-digit',
    year: 'numeric'
  });
};

export const capitalizeWords = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const formatSourceDate = (item, name, complete = false, gmt = false) => {
  const date = { ...item }[name];
  if (date) {
    if (date.includes('T')) {
      return shortDate(new Date(date), true, complete, gmt);
    }
    const timedDate = `${date}T00:00:00`;
    return shortDate(new Date(timedDate), true, complete, gmt);
  }

  return '--';
};

export const scrollHandler = (show) => {
  document.body.style.overflow = show ? '' : 'hidden';
};

/**
 * @param {string} string
 */
export const stringToSlug = (string) => {
  let str = string.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();

  const from = 'àáäãâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
};

export const simplifyString = (string) =>
  string
    .toString()
    .toLowerCase()
    .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
    .replace(/[èÈéÉêÊëË]+/g, 'e')
    .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
    .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
    .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
    .replace(/[ýÝÿŸ]+/g, 'y')
    .replace(/[ñÑ]+/g, 'n')
    .replace(/[çÇ]+/g, 'c')
    .replace(/[ß]+/g, 'ss')
    .replace(/[Ææ]+/g, 'ae')
    .replace(/[Øøœ]+/g, 'oe');

export const chunk = (input, size) =>
  input.reduce(
    (arr, item, idx) =>
      idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    []
  );

export const queryFormatter = (query) => {
  if (!query || Object.keys(query).length === 0) {
    return null;
  }
  return Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
};

export const splitFilterValues = (filters) => {
  if (!filters) {
    return [];
  }
  return filters.map((value) => {
    return {
      field: value.field,
      values: value.values.split(',')
    };
  });
};
