import Vue from 'vue';

import store from './store';
import router from './router';
const App = () => import(/* webpackChunkName: "app-component" */ './App.vue');

Promise.all([
  import(/* webpackChunkName: "vue-meta-plugin" */ 'vue-meta'),
  import(/* webpackChunkName: "v-tooltip-plugin" */ 'v-tooltip')
]).then(([VueMeta, VTooltip]) => {
  Vue.use(VueMeta);
  Vue.use(VTooltip);

  Vue.config.productionTip = false;

  Vue.config.devtools = process.env.NODE_ENV === 'development';

  Vue.config.router = router;
  Vue.config.store = store;

  const app = new Vue({
    store,
    router,
    render: (h) => h(App)
  }).$mount('#app');

  if (window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor;
  }
});
