import { Route } from 'vue-router';

import Store from '@/core/Store';
import MainLayout from '@/core/Components/MainLayout';
import RoutePath from '@/route-path';

const Performance = () =>
  import(
    /* webpackChunkName: "performance-main-page" */
    '../Pages/Performance'
  );
const PerformanceDetail = () =>
  import(/* webpackChunkName: "performance-detail-page" */ '../Pages/Detail');

// eslint-disable-next-line @typescript-eslint/ban-types
const protectedRoute = async (to: Route, from: Route, next: Function) => {
  if (to.path === '/auth/login') {
    next();
    return;
  }
  const result = await Store.dispatch('auth/check', false);

  if (!Store.getters['auth/canCurrentUserSeePerformance']) {
    next({
      name: RoutePath.ACTIVITY
    });
    return;
  }
  next(result);
};

export default [
  {
    path: RoutePath.PERFORMANCE,
    component: MainLayout,
    meta: {
      title: 'Desempenho'
    },
    children: [
      {
        path: RoutePath.PERFORMANCE,
        name: RoutePath.PERFORMANCE,
        beforeEnter: protectedRoute,
        component: Performance
      },
      {
        path: RoutePath.PERFORMANCE_DETAIL,
        name: RoutePath.PERFORMANCE_DETAIL,
        beforeEnter: protectedRoute,
        component: PerformanceDetail
      }
    ]
  }
];
