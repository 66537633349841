import { pipe, useBusy, useErrors } from '@/helpers';

import { state } from './billGroup.state';
import actions from './billGroup.actions';
import getters from './billGroup.getters';
import mutations from './billGroup.mutations';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

export default useHelpers({
  state,
  actions,
  getters,
  mutations,
  namespaced
});
