import platform from 'platform';

export interface SupportWindow extends Window {
  organization_token: string;
  icon_color: string;
  init_minimal: string;
  show_chat_icon: string;
  name: string;
  mail: string;
  extra_params: {
    Telefone: string;
    Cliente: string;
    Empresa: string;
    Navegador: string;
    Sistema_Operacional: string;
  };
}

export default class SupportUtil {
  static instance = new SupportUtil();

  organizationToken: string;
  scriptId: string;
  window: SupportWindow;
  userDevice: {
    browserName: string;
    browserVersion: string;
    os: string;
  };

  constructor() {
    this.organizationToken = 'b654ccdd1e965a4785d561c251394384633c1314';
    this.scriptId = 'support-script';
    this.window = window as any;

    const { name, version, os } = platform.parse(window.navigator.userAgent);

    this.userDevice = {
      browserName: '',
      browserVersion: '',
      os: ''
    };

    this.userDevice.browserName = name;
    this.userDevice.browserVersion = version;

    this.userDevice.os = os;
  }

  getBrowser() {
    if (!this.userDevice.browserName) {
      return '';
    }

    return `${this.userDevice.browserName} ${this.userDevice.browserVersion}`;
  }

  getOs() {
    if (!this.userDevice.os) {
      return '';
    }

    return this.userDevice.os;
  }

  removeScript() {
    const currentScript = document.getElementById(this.scriptId);
    if (currentScript) {
      document.head.removeChild(currentScript);
    }
    const injectedHtml = document.getElementById('tiflux--chat');
    if (injectedHtml) {
      document.body.removeChild(injectedHtml);
    }
  }

  hasScript() {
    const currentScript = document.getElementById(this.scriptId);
    const injectedHtml = document.getElementById('tiflux--chat');
    return !!currentScript || !!injectedHtml;
  }

  mountScript() {
    if (this.hasScript()) {
      return;
    }

    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.setAttribute('id', this.scriptId);
    newScript.src = `https://public-assets.tiflux.com/chat_widget.js?organization_token=${this.organizationToken}`;

    document.head.appendChild(newScript);
  }

  setSupportScript(
    name: SupportWindow['name'] = '',
    mail: SupportWindow['mail'] = '',
    extraParams: Partial<SupportWindow['extra_params']> = {}
  ) {
    this.window.organization_token = this.organizationToken;
    this.window.icon_color = '#4e566d';
    this.window.init_minimal = 'true';
    this.window.show_chat_icon = 'true';
    this.window.name = name;
    this.window.mail = mail;
    this.window.extra_params = {
      Telefone: '',
      Cliente: '',
      Empresa: '',
      Navegador: this.getBrowser(),
      Sistema_Operacional: this.getOs(),
      ...extraParams
    };

    this.mountScript();
  }
}
