import { client } from '@/axios';

class AttachmentUtil {
  constructor() {
    this.maxFileSize = 20;
    this.mbDivisor = 1024;
    this.maxTitleLength = 80;
    this.maxDescriptionLength = 500;
    this.client = client;
    this.attachmentExtensionsIcons = [
      'csv',
      'doc',
      'gif',
      'jpg',
      'mov',
      'pdf',
      'png',
      'ppt',
      'svg',
      'txt',
      'xls',
      'zip'
    ];
  }

  /**
   * Regular expression to remove accents, cedillas and special characters
   */
  removeSpecialCharacters(str) {
    return str
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
      .replace(/[èÈéÉêÊëË]+/g, 'e')
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
      .replace(/[ýÝÿŸ]+/g, 'y')
      .replace(/[ñÑ]+/g, 'n')
      .replace(/[çÇ]+/g, 'c')
      .replace(/[ß]+/g, 'ss')
      .replace(/[Ææ]+/g, 'ae')
      .replace(/[Øøœ]+/g, 'oe')
      .replace(/[%]+/g, 'pct')
      .replace(/\./g, '-')
      .replace(/[^\w-^\s]+/g, '');
  }

  /**
   * Remove accents, cedillas and special characters from a string
   */
  normalizeTitle(str) {
    const lastDot = str.lastIndexOf('.');
    const fileName = str.substring(0, lastDot);
    const ext = str.substring(lastDot + 1);
    return `${this.removeSpecialCharacters(fileName)}.${ext}`;
  }

  normalizeTitleWithoutExtension(str) {
    const title = this.normalizeTitle(str);
    const lastDot = title.lastIndexOf('.');
    const lastCharIndex =
      lastDot > this.maxTitleLength ? this.maxTitleLength : lastDot;

    return title.substring(0, lastCharIndex);
  }

  normalizeDescription(str) {
    const description = str.trim();
    if (description.length < this.maxDescriptionLength) {
      return description;
    }

    return description.substring(0, this.maxDescriptionLength);
  }

  /**
   * Regular expression to remove accents, cedillas and special characters to slug
   */
  preSlug(str) {
    return str
      .toString()
      .toLowerCase()
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
      .replace(/[èÈéÉêÊëË]+/g, 'e')
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
      .replace(/[ýÝÿŸ]+/g, 'y')
      .replace(/[ñÑ]+/g, 'n')
      .replace(/[çÇ]+/g, 'c')
      .replace(/[ß]+/g, 'ss')
      .replace(/[Ææ]+/g, 'ae')
      .replace(/[Øøœ]+/g, 'oe')
      .replace(/[%]+/g, 'pct')
      .replace(/\./g, '-')
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  /**
   * Transforms string into slug format
   */
  slugify(str) {
    const lastDot = str.lastIndexOf('.');
    const fileName = str.substring(0, lastDot);
    const ext = str.substring(lastDot + 1);
    return `${this.preSlug(fileName)}.${ext}`;
  }

  async downloadImage(url, title) {
    const { data } = await this.client({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    });

    const resolvedUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    document.body.appendChild(link);
    link.href = resolvedUrl;
    link.setAttribute('download', title);
    link.click();
  }

  getFileExtension(fileName) {
    if (!fileName) {
      return null;
    }
    const dotIndex = fileName.lastIndexOf('.');
    return fileName.slice(dotIndex + 1);
  }

  largeFilesReason(length) {
    if (!length || length > 1) {
      return `O tamanho dos anexos excede o limite permitido de ${this.maxFileSize}mb.`;
    }
    return `O tamanho do anexo excede o limite permitido de ${this.maxFileSize}mb.`;
  }

  getFileSizeInMb(size) {
    return size / this.mbDivisor / this.mbDivisor;
  }

  isFileSizeLargeThanMax(size, inMb = false) {
    if (inMb) {
      return size > this.maxFileSize;
    }
    return this.getFileSizeInMb(size) > this.maxFileSize;
  }

  getFiles(files) {
    return [...files];
  }
}

export default new AttachmentUtil();
