import { pipe, useBusy, useErrors } from '@/helpers';
import DocumentFactory from '@/core/Services/document';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  documents: [],
  otherBillOpenDocuments: [],
  checked_ids: [],
  received: []
});

const theState = getDefaultState();

const getters = {
  documents: (state) => state.documents,
  otherBillOpenDocuments: (state) => state.otherBillOpenDocuments,
  checked_ids: (state) => state.checked_ids,
  received: (state) => state.received
};

const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },
  SET_OTHER_DOCUMENTS(state, otherBillOpenDocuments) {
    state.otherBillOpenDocuments = otherBillOpenDocuments;
  },
  SET_CHECKED_IDS(state, checkedIds) {
    state.checked_ids = checkedIds;
  },
  SET_RECEIVED(state, received) {
    state.received = received;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async receiveNew({ commit, dispatch }, { data, billActivityId = null }) {
    commit('SET_BUSY', 'receiveNew');

    try {
      await DocumentFactory.receiveNew(data);

      if (billActivityId) {
        await dispatch('bill/fetch', billActivityId, {
          root: true
        });
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  async fetch({ commit }, billActivityId) {
    commit('SET_BUSY', 'fetch');

    try {
      const data = await DocumentFactory.getAllDocuments(billActivityId);

      const documentConcatenatedIds = data
        .filter(({ firstVisualizationDate }) => !!firstVisualizationDate)
        .map(
          ({ documentId, documentTypeId }) => `${documentId}-${documentTypeId}`
        );

      commit('SET_CHECKED_IDS', documentConcatenatedIds || []);
      commit('SET_DOCUMENTS', data || []);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('SET_BUSY');
    }
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
