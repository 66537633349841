import UserFactory from '@/modules/Settings/Modules/User/Services';
import { Store } from 'vuex';
import { capitalizeSentence } from '@/helpers';
import axios from 'axios';
import IUserStore from '../Interfaces/store';

export default {
  async fetch({ commit, state }: Store<IUserStore>) {
    commit('SET_BUSY', 'fetch');
    try {
      const {
        search: search,
        pagination: { perPage, current_page },
        cancelToken
      } = state;

      const page = current_page;

      const params: {
        search: string;
        page: number;
        perPage: number;
        activityId?: number;
      } = {
        search,
        page,
        perPage
      };

      if ('activityId' in state && !!state.activityId) {
        params.activityId = state.activityId;
      }

      if (cancelToken != null) {
        commit('SET_HAVE_CANCELLED', true);
        cancelToken.cancel();
      }
      const source = axios.CancelToken.source();
      commit('SET_CANCEL_TOKEN', source);
      const data = await UserFactory.getUsers(params, source.token);

      const { content, totalPages: pages, totalElements: total } = data;

      commit(
        'SET_LIST',
        content.map(({ name, ...list }) => ({
          ...list,
          name: capitalizeSentence(name)
        }))
      );

      commit('UPDATE_PAGINATION', {
        total,
        pages
      });
    } catch (e: any) {
      if (!axios.isCancel(e)) {
        commit('SET_ERROR', {
          key: 'user',
          value: 'Erro no envio serviço'
        });
      }
    } finally {
      if (state.haveCancelled) {
        commit('SET_HAVE_CANCELLED', false);
      } else {
        commit('SET_BUSY');
        commit('SET_CANCEL_TOKEN', null);
      }
    }
  },

  setSearch(
    { commit }: Store<IUserStore>,
    searchString: IUserStore['search'] = ''
  ) {
    commit('SET_SEARCH', searchString);
  },
  setPage(
    { commit }: Store<IUserStore>,
    pageNumber: IUserStore['pagination']['current_page'] = 1
  ) {
    commit('SET_PAGE', pageNumber);
  },
  setActivityId(
    { commit }: Store<IUserStore>,
    activityId: IUserStore['activityId']
  ) {
    commit('SET_ACTIVITY_ID', activityId);
  },
  async delete({ commit }: Store<IUserStore>, userId: number) {
    commit('SET_BUSY', 'delete');

    try {
      await UserFactory.deleteUser(userId);
    } catch (e: any) {
      commit('SET_ERROR', {
        key: 'delete',
        value: 'Erro no envio serviço'
      });
    } finally {
      commit('SET_BUSY');
    }
  }
};
