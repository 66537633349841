import Service from '@/core/Services/service';
import { StreamSaverUtilClass } from '@/core/Utils/stream-saver';

class Factory extends Service {
  async getBillActivities(activity, params, cancelToken = null) {
    const result = await this.get(
      `/activities/${activity}/bill_activities`,
      params,
      cancelToken
    );
    return result.data;
  }

  async downloadBillActivitiesReport(
    activityId,
    billActivitiesReportName,
    formattedQuery
  ) {
    const streamSaver = new StreamSaverUtilClass('application/vnd.ms-excel');

    await streamSaver.fetchAndDownload(
      `activities/${activityId}/bill_activities/report${
        formattedQuery ? `?${formattedQuery}` : ''
      }`,
      billActivitiesReportName
    );
  }

  async downloadBillActivitiesFinishedReport(
    activityId,
    billActivitiesReportName,
    formattedQuery
  ) {
    const streamSaver = new StreamSaverUtilClass('application/vnd.ms-excel');

    await streamSaver.fetchAndDownload(
      `activities/${activityId}/bill_activities/finished/report${
        formattedQuery ? `?${formattedQuery}` : ''
      }`,
      billActivitiesReportName
    );
  }

  async getBillActivitiesStats(activityId, params, cancelToken = null) {
    const result = await this.get(
      `/activities/${activityId}/bill_activities/stats`,
      params,
      cancelToken
    );
    return result.data;
  }
}

export default new Factory();
