import { Route } from 'vue-router';
import Store from '@/core/Store';

const Auth = () =>
  import(/* webpackChunkName: "auth-main-page" */ '../Pages/Auth');
const LoginAuth = () =>
  import(/* webpackChunkName: "auth-login-page" */ '../Components/Login.vue');
const ResetAuth = () =>
  import(/* webpackChunkName: "auth-reset-page" */ '../Components/Reset.vue');
const TokenAuth = () =>
  import(/* webpackChunkName: "auth-token-page" */ '../Components/Token.vue');
const RecoverAuth = () =>
  import(
    /* webpackChunkName: "auth-recover-page" */
    '../Components/Recover.vue'
  );
const CredentialsExpiredAuth = () =>
  import(
    /* webpackChunkName: "auth-credentials-expired-page" */
    '../Components/CredentialsExpired.vue'
  );
const TwoFactorAuth = () =>
  import(
    /* webpackChunkName: "auth-two-factor-page" */
    '../Components/TwoFactor.vue'
  );

import RoutePath from './paths';

export default [
  {
    path: RoutePath.AUTH,
    component: Auth,
    beforeEnter: async (to: Route, from: Route, next: CallableFunction) => {
      if (to.path === '/auth/login') {
        next();
        return;
      }
      next(await Store.dispatch('auth/check', true));
    },
    redirect: RoutePath.AUTH_LOGIN,
    children: [
      {
        name: RoutePath.AUTH_LOGIN,
        path: RoutePath.AUTH_LOGIN,
        component: LoginAuth
      },
      {
        name: RoutePath.AUTH_RESET,
        path: RoutePath.AUTH_RESET,
        component: ResetAuth
      },
      {
        name: RoutePath.AUTH_RECOVER,
        path: RoutePath.AUTH_RECOVER,
        component: RecoverAuth
      },
      {
        name: RoutePath.AUTH_RECOVER_TOKEN,
        path: RoutePath.AUTH_RECOVER_TOKEN,
        component: TokenAuth
      },
      {
        name: RoutePath.AUTH_CREDENTIALS_EXPIRED,
        path: RoutePath.AUTH_CREDENTIALS_EXPIRED,
        component: CredentialsExpiredAuth
      },
      {
        name: RoutePath.AUTH_TWO_FACTOR,
        path: RoutePath.AUTH_TWO_FACTOR,
        component: TwoFactorAuth,
        beforeEnter: (to: Route, from: Route, next: CallableFunction) => {
          if (!Store.state.auth.deviceIdentity) {
            next('/auth/login');
            return;
          }
          next();
        }
      }
    ]
  }
];
