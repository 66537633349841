import { pipe, useBusy, useErrors } from '@/helpers';

import { state } from './auth.state';
import actions from './auth.actions';
import getters from './auth.getters';
import mutations from './auth.mutations';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

export default useHelpers({
  state,
  actions,
  getters,
  mutations,
  namespaced
});
