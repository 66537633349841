import Vue from 'vue';
import { pipe, useBusy, useErrors } from '@/helpers';
import ChecklistFactory from '@/core/Services/checklist';

const resolveStatus = (status, current) => {
  if (current === 'NOT_APPLY') {
    return status === 'DONE' ? 0 : -1;
  }

  if (status === 'NOT_APPLY') {
    return current === 'DONE' ? 0 : 1;
  }

  return status === 'DONE' ? 1 : -1;
};

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const theState = {
  checklist: []
};

const getters = {
  checklist: (state) => state.checklist
};

const mutations = {
  SET_CHECKLIST(state, checklist) {
    state.checklist = checklist;
  },

  UPDATE_CHECKLIST_ITEM(state, checklistObject) {
    const index = state.checklist.findIndex(
      ({ billActivityChecklistItemId }) =>
        +billActivityChecklistItemId ===
        +checklistObject.billActivityChecklistItemId
    );

    if (index === -1) {
      return;
    }

    Vue.set(state.checklist, index, checklistObject);
  }
};

const actions = {
  async update({ commit, rootState }, { id, bill, status, current }) {
    /**
     * We bail if we are trying to update the status
     * with the same status
     */
    if (status === current) {
      return;
    }

    /**
     * If we are toggling a checklist item from the current bill activity
     * we update it too
     */
    const isCurrentBillActivity = rootState.bill?.bill?.billActivityId === bill;

    try {
      const result = await ChecklistFactory.updateChecklist(id, status);
      commit('UPDATE_CHECKLIST_ITEM', result);

      const resolvedStatus = resolveStatus(status, current);

      commit(
        'activity/ADD_TO_DONE_COUNT',
        { resolvedStatus, id: bill },
        { root: true }
      );
      if (!isCurrentBillActivity) {
        return;
      }

      commit('bill/ADD_TO_DONE_COUNT', resolvedStatus, { root: true });
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
