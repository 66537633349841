<script>
import MainHeader from './MainHeader.vue';
import MainNav from './MainNav.vue';

export default {
  components: {
    MainHeader,
    MainNav
  },

  data: () => ({
    mouseInNav: false,
    mouseInHeader: false
  }),

  metaInfo() {
    return {
      title: '',
      titleTemplate: '%s | Ciclys'
    };
  }
};
</script>

<template>
  <div>
    <MainHeader
      :mouse-in-nav="mouseInNav"
      @mouseenter-logo="mouseInHeader = true"
      @mouseleave-logo="mouseInHeader = false"
    />
    <RouterView />
    <MainNav
      :mouse-in-header="mouseInHeader"
      @mouseenter.native="mouseInNav = true"
      @mouseleave.native="mouseInNav = false"
    />
  </div>
</template>
