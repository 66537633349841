<script>
import { mapGetters } from 'vuex';
import UpdateAppUtil from '@/core/Utils/UpdateApp';

import RoutePath from '../../../route-path';

export default {
  components: {
    HeartIcon: () =>
      import(
        /* webpackChunkName: "heart-icon" */
        'heart-design-system/components/basics/heart-icon'
      )
  },
  props: {
    mouseInHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'canCurrentUserEditUsers',
      'canCurrentUserSeePerformance',
      'canCurrentUserSeeDashboard',
      'canCurrentUserSeePanel',
      'canCurrentUserSeeActivities',
      'isAdminUser'
    ]),
    cssVars() {
      return {
        '--open-width': '90px',
        '--closed-width': '60px',
        '--item-height': '72px',
        '--max-item-gap': '40px',
        '--reduced-item-height': '40px'
      };
    },
    activitiesRoute() {
      const activityId = this.$route?.params?.activityId;
      const query = this.$route?.query;
      if (activityId !== 0 && !activityId) {
        return {
          name: RoutePath.ACTIVITY
        };
      }
      return {
        name: RoutePath.ACTIVITY,
        params: { activity_id: activityId },
        query
      };
    },
    panelsRoute() {
      const panelId = this.$route?.params?.panelId;
      const panelPageId = this.$route?.params?.panelPageId;
      const query = this.$route?.query;
      if (!panelId) {
        return {
          name: RoutePath.PANELS
        };
      }
      return {
        name: RoutePath.PANELS_DETAIL,
        params: { panelId, panelPageId },
        query
      };
    }
  },
  methods: {
    checkAppVersion() {
      UpdateAppUtil.instance.checkVersion();
    }
  }
};
</script>

<template>
  <nav
    ref="nav"
    class="nav"
    :class="mouseInHeader && 'nav--open'"
    :style="cssVars"
  >
    <ul class="nav__list">
      <li v-if="canCurrentUserSeeActivities" class="nav__item">
        <RouterLink
          :to="activitiesRoute"
          class="nav__link"
          active-class="nav__link--active"
          :class="{
            'nav__link--active': $route.matched[0].meta.title === 'Detalhes'
          }"
          @click.native="checkAppVersion"
        >
          <HeartIcon
            class="nav__icon"
            :class="{
              'nav__icon--active':
                $route.matched[0].meta.title === 'Minhas Atividades' ||
                $route.matched[0].meta.title === 'Detalhes'
            }"
            icon="graphic-icon-menu-minhas-atividades"
            size="medium-large"
          />
          <div class="nav__label">Minhas atividades</div>
        </RouterLink>
      </li>

      <li class="nav__item">
        <RouterLink
          to="/todas-as-contas"
          class="nav__link"
          active-class="nav__link--active"
          @click.native="checkAppVersion"
        >
          <HeartIcon
            class="nav__icon"
            :class="{
              'nav__icon--active':
                $route.matched[0].meta.title === 'Todas as contas'
            }"
            icon="graphic-icon-menu-todas-as-contas"
            size="medium-large"
          />
          <div class="nav__label">Todas as contas</div>
        </RouterLink>
      </li>

      <li v-if="canCurrentUserSeeDashboard" class="nav__item">
        <RouterLink
          to="/dashboard"
          class="nav__link"
          active-class="nav__link--active"
          @click.native="checkAppVersion"
        >
          <HeartIcon
            class="nav__icon"
            :class="{
              'nav__icon--active': $route.matched[0].meta.title === 'Dashboard'
            }"
            icon="graphic-icon-menu-dashboard"
            size="medium-large"
          />
          <div class="nav__label">Dashboard</div>
        </RouterLink>
      </li>

      <li v-if="canCurrentUserSeePerformance" class="nav__item">
        <RouterLink
          to="/desempenho"
          class="nav__link"
          active-class="nav__link--active"
          @click.native="checkAppVersion"
        >
          <HeartIcon
            class="nav__icon"
            :class="{
              'nav__icon--active': $route.matched[0].meta.title === 'Desempenho'
            }"
            icon="graphic-icon-menu-desempenho"
            size="medium-large"
          />
          <div class="nav__label">Desempenho</div>
        </RouterLink>
      </li>

      <li v-if="canCurrentUserSeePanel" class="nav__item">
        <RouterLink
          :to="panelsRoute"
          class="nav__link"
          active-class="nav__link--active"
          @click.native="checkAppVersion"
        >
          <HeartIcon
            class="nav__icon"
            :class="{
              'nav__icon--active': $route.matched[0].meta.title === 'Painéis'
            }"
            icon="graphic-icon-menu-paineis"
            size="medium-large"
          />
          <div class="nav__label">Painéis</div>
        </RouterLink>
      </li>

      <li
        v-if="canCurrentUserEditUsers"
        class="nav__item"
        data-test="settings-icon"
      >
        <RouterLink
          to="/configuracoes"
          class="nav__link"
          active-class="nav__link--active"
          @click.native="checkAppVersion"
        >
          <HeartIcon
            class="nav__icon"
            :class="{
              'nav__icon--active':
                $route.matched[0].meta.title === 'Configurações'
            }"
            icon="graphic-icon-menu-configura-es"
            size="medium-large"
          />
          <div class="nav__label">Configurações</div>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
.nav {
  top: 70px;
  bottom: 0;
  left: 0;
  width: var(--open-width);
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: var(--colors-brand-fair);
  box-shadow: var(--shadows-level1);
  padding: 12px 0;
  z-index: 18;

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &__item {
    flex: 1;
    min-height: var(--item-height);
    max-height: calc(var(--item-height) + var(--max-item-gap));
    position: relative;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0;
    padding: 5px 10px;
    top: 50%;
    height: var(--item-height);
    width: var(--open-width);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: var(--colors-neutral-dark);
    text-align: center;
    .nav__icon {
      > svg path {
        fill: currentColor;
      }
    }
    .nav__label {
      display: block;
      font-size: var(--font-sizes-body-small);
      line-height: var(--line-heights-medium);
      margin-top: var(--spacing-quarck);
      max-height: calc(
        var(--line-heights-medium) * var(--font-sizes-body-small) * 2
      );
      overflow: hidden;
    }

    &:hover {
      color: var(--colors-neutral-darkest);
    }

    &--active {
      color: var(--colors-brand-darkest);
      background-color: var(--colors-brand-lightest);
      font-weight: var(--font-weights-medium);
      position: relative;
      border-radius: 0 var(--radii-sm) var(--radii-sm) 0;

      &:hover {
        color: var(--colors-brand-dark);
      }

      &::before {
        top: 0;
        left: 0;
        width: 4px;
        content: '';
        height: 100%;
        position: absolute;
        background: currentColor;
        border-radius: 0 2px 2px 0;
      }
    }
  }

  &__icon {
    height: 24px;
    width: 24px;
    transition: top var(--durations-short);
    > svg path {
      transition: fill var(--durations-medium);
      fill: var(--colors-neutral-base);
    }

    &--active {
      > path {
        fill: var(--colors-brand-darkest);
      }
    }
  }

  @media (max-width: 1200px) {
    width: var(--closed-width);
    transition: width var(--durations-medium);
    &__link {
      font-size: 0;
      line-height: 0;
      height: var(--reduced-item-height);
      width: var(--closed-width);
      transition: width var(--durations-medium);
      &:hover {
        color: var(--colors-neutral-dark);
      }
      .nav__label {
        display: none;
      }
    }
    &:hover,
    &--open {
      width: var(--open-width);
      margin-right: -30px;
      .nav__link {
        font-size: var(--font-sizes-body-small);
        line-height: 1;
        height: var(--item-height);
        width: var(--open-width);
        color: var(--colors-neutral-base);
        .nav__label {
          display: block;
          animation: fade_in_text var(--durations-medium) linear;
          @keyframes fade_in_text {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        }
        &:hover {
          color: var(--colors-neutral-darkest);
        }
        &--active,
        &--active:hover {
          color: var(--colors-brand-darkest);
        }
      }
    }
  }
}
</style>
