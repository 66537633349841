import ISearchCodeOption from '@/core/Interfaces/search-code-option';
import UserUtil from '@/modules/Settings/Modules/User/Utils';
import { CancelTokenSource } from 'axios';
import { getDefaultState } from './all-bills.state';
import { INotFound } from '@/core/Interfaces/not_found';
import { AllBillsStore, AllBillsBill } from '@types';

export default {
  SET_PAGINATION(
    state: AllBillsStore,
    pagination: {
      total: number;
      pages: number;
      perPage: number;
      current_page: number;
    } = {
      total: 0,
      pages: 0,
      perPage: UserUtil.limitPerPage,
      current_page: 1
    }
  ) {
    state.pagination = pagination;
  },

  SET_LIST(state: AllBillsStore, list: AllBillsBill[] = []) {
    state.list = list;
  },

  SET_SEARCH(state: AllBillsStore, search: string) {
    state.search = search;
  },

  SET_SEARCH_CODE(state: AllBillsStore, searchCode: string) {
    state.searchCode = searchCode;
  },

  SET_NOT_FOUND(state: AllBillsStore, notFound: INotFound = null) {
    state.notFound = notFound;
  },

  REMOVE_SEARCH_ITEM(state: AllBillsStore, searchValue: string) {
    if (!state.search?.length) {
      return;
    }
    state.search = state.search
      .split(',')
      .filter((value) => value !== searchValue)
      .join(',');
  },

  REMOVE_NOT_FOUND_ITEM(
    state: AllBillsStore,
    { searchCode, searchValue }: { searchCode: string; searchValue: string }
  ) {
    const itemsNotFound = state.notFound?.items?.find(
      (value) => value.searchCode === searchCode
    );
    if (!!itemsNotFound) {
      itemsNotFound.values = itemsNotFound.values.filter(
        (value) => value !== searchValue
      );
    }
  },

  UPDATE_PAGINATION(
    state: AllBillsStore,
    { total, pages }: { total: number; pages: number }
  ) {
    state.pagination.total = total;
    state.pagination.pages = pages;
  },

  SET_PAGE(state: AllBillsStore, page: number) {
    state.pagination.current_page = page;
  },

  SET_SEARCH_CODE_OPTIONS(
    state: AllBillsStore,
    searchCodeOptions: ISearchCodeOption[]
  ) {
    state.searchCodeOptions = searchCodeOptions;
  },

  SET_CANCEL_TOKEN(state: AllBillsStore, cancelToken: CancelTokenSource) {
    state.cancelToken = cancelToken;
  },

  SET_HAVE_CANCELLED(state: AllBillsStore, haveCancelled: boolean) {
    state.haveCancelled = haveCancelled;
  },

  RESET_STATE(state: AllBillsStore) {
    Object.assign(state, getDefaultState());
  }
};
