import Service from '@/core/Services/service';

class Factory extends Service {
  async createAttachment(params) {
    return await this.post('/attachments', params);
  }

  async updateAttachment(attachmentId, params) {
    return await this.put(`/attachments/${attachmentId}`, params);
  }

  async deleteAttachment(attachmentId) {
    return await this.delete(`/attachments/${attachmentId}`);
  }

  async getAttachments(billId) {
    return await this.get(`/attachments/bills/${billId}`);
  }

  async createPreSignedUrl(params) {
    return await this.post('/attachments/pre-signed-url', params);
  }

  async getPreSignedUrl(attachmentId) {
    return await this.get(`/attachments/pre-signed-url/${attachmentId}`);
  }
}

export default new Factory();
