<script>
export default {
  components: {
    HeartIllustration: () =>
      import('heart-design-system/components/basics/heart-illustration')
  }
};
</script>

<template>
  <main class="error-not-found">
    <HeartIllustration type="not-found" class="error-not-found__icon" />
    <h2 class="error-not-found__title">Página não encontrada.</h2>
    <p class="error-not-found__message">
      O conteúdo que você tentou acessar<br />
      não está disponível ou não existe.
    </p>
  </main>
</template>

<style lang="scss">
.error-not-found {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__title {
    font-size: var(--font-sizes-h3);
    font-weight: 400;
    color: var(--colors-neutral-dark);
    margin-bottom: 8px;
  }

  &__message {
    font-size: var(--font-sizes-body);
    color: var(--colors-neutral-dark);
    text-align: center;
  }

  &__icon {
    padding-bottom: 28px;
  }
}
</style>
