import Service from '@/core/Services/service';

class Factory extends Service {
  async getCompanies() {
    const { data } = await this.get('/companies');
    return data;
  }

  // Configurations requests
  async getAllConfigurations() {
    const { data } = await this.get('/configurations');
    return data;
  }

  async getAssetsConfigurations() {
    const { data } = await this.get('/configurations?startsWith=ASSET_');
    return data;
  }

  async getFrontConfigurations() {
    const { data } = await this.get('/configurations?startsWith=F_');
    return data;
  }

  async getBillActivityAdvancedSearches() {
    const { data } = await this.get(
      '/configurations/bill-activity-advanced-searches'
    );
    return data;
  }

  async getBillAdvancedSearches() {
    const { data } = await this.get('/configurations/bill-advanced-searches');
    return data;
  }

  async getEnabledModules() {
    const { data } = await this.get('/configurations/enabled-modules');
    return data;
  }
}

export default new Factory();
