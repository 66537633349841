import IBillActivity from '@/core/Interfaces/bill_activity';
import { getDefaultState } from './dashboard.state';
import IDashboardComplete from '../Interfaces/complete';
import IDashboardStore from '../Interfaces/store';
import IDashboardGroup from '../Interfaces/group';
import IDashboardHeader from '../Interfaces/header';
import IDashboardHeaderOverview from '../Interfaces/header_overview';
import IDashboardHeaderOverall from '../Interfaces/header_overall';
import IDashboardActivity from '../Interfaces/activity';

export default {
  SET_ACTIVITIES(state: IDashboardStore, activities: IDashboardActivity[]) {
    state.activities = activities;
  },
  SET_COMPLETE(state: IDashboardStore, complete: IDashboardComplete) {
    state.complete = complete;
  },
  SET_DASHBOARD_ID(state: IDashboardStore, dashboardId: number) {
    state.dashboardId = dashboardId;
  },
  SET_GROUP_ID(state: IDashboardStore, groupId: number) {
    state.groupId = groupId;
  },
  SET_ACTIVITY_ID(state: IDashboardStore, activityId: number) {
    state.activityId = activityId;
  },
  SET_DASHBOARDS_LIST(state: IDashboardStore, dashboards_list: any) {
    state.dashboards_list = dashboards_list;
  },
  SET_GROUPS(state: IDashboardStore, groups: IDashboardGroup[]) {
    state.groups = groups;
  },
  SET_HEADER(state: IDashboardStore, header: IDashboardHeader) {
    state.header = header;
  },
  SET_OVERVIEW(state: IDashboardStore, overview: IDashboardHeaderOverview) {
    state.overview = overview;
  },
  SET_OVERALL(state: IDashboardStore, overall: IDashboardHeaderOverall) {
    state.overall = overall;
  },
  SET_SENT(state: IDashboardStore, sent: IDashboardActivity) {
    state.sent = sent;
  },
  SET_EDITED(state: IDashboardStore, edited: IDashboardActivity) {
    state.edited = edited;
  },
  SET_BILL_ACTIVITIES(
    state: IDashboardStore,
    bill_activities: IBillActivity[]
  ) {
    state.bill_activities = bill_activities;
  },
  SET_DIRECTION(state: IDashboardStore, direction: any) {
    state.direction = direction;
  },
  SET_COLUMN(state: IDashboardStore, column: any) {
    state.column = column;
  },
  SET_PAY_STATUS_SET(state: IDashboardStore, billStatusSet: any) {
    state.billStatusSet = billStatusSet;
  },
  UPDATE_PAGINATION(
    state: IDashboardStore,
    { total, pages }: { total: number; pages: number }
  ) {
    state.pagination.total = total;
    state.pagination.pages = pages;
  },
  SET_PAGE(state: IDashboardStore, page: number) {
    state.pagination.current_page = page;
  },
  SET_VALUE_PRODUCED(state: IDashboardStore, valueProduced: number) {
    state.valueProduced = valueProduced;
  },
  SET_CAROUSEL_TRANSITION_TIME(state: IDashboardStore, time: number) {
    state.carouselTransitionTime = time;
  },
  RESET_STATE(state: IDashboardStore) {
    Object.assign(state, getDefaultState());
  }
};
