import Vue from 'vue';
import { pipe, useBusy, useErrors } from '@/helpers';
import Axios from '@/axios';
import AttachmentUtil from '@/core/Utils/attachment';

const resolveStatus = (status, current) => {
  if (current === 'CANCELED') {
    return status === 'DONE' ? 0 : 1;
  }

  if (status === 'CANCELED') {
    return current === 'DONE' ? 0 : -1;
  }

  return status === 'DONE' ? -1 : 1;
};

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  pendencies: [],
  otherBillPendencies: [],
  pendenciesType: [],
  edit: null,
  current: null
});

const theState = getDefaultState();

const getters = {
  titles: (state) =>
    state.pendenciesType.map(({ pendencyId: value, title: label }) => ({
      value,
      label
    })),
  dependsUponRequired: (state) =>
    state.pendenciesType.map(({ pendencyId, dependsUponRequired }) => ({
      pendencyId,
      dependsUponRequired
    })),
  attachmentRequired: (state) =>
    state.pendenciesType.map(({ pendencyId, attachmentRequired }) => ({
      pendencyId,
      attachmentRequired
    })),

  pendencies: (state) => state.pendencies,
  otherBillPendencies: (state) => state.otherBillPendencies
};

const mutations = {
  PUSH_PENDENCY(state, pendency) {
    state.pendencies.push(pendency);
  },

  SET_EDITING(state, pendency = null) {
    state.edit = pendency;
  },

  SET_PENDENCIES(state, pendencies) {
    state.pendencies = pendencies;
  },

  SET_OTHER_BILL_PENDENCIES(state, otherBillPendencies) {
    state.otherBillPendencies = otherBillPendencies;
  },

  SET_PENDENCIES_TYPE(state, pendenciesType) {
    state.pendenciesType = pendenciesType;
  },

  SET_FINISHED_BY(state, { id, ...user }) {
    const pendency =
      state.pendencies.find(
        ({ billActivityPendencyId }) => billActivityPendencyId === id
      ) ||
      state.otherBillPendencies.find(
        ({ billActivityPendencyId }) => billActivityPendencyId === id
      );

    if (pendency) {
      Vue.set(pendency, 'finishedBy', user);
    }
  },

  SET_STATUS(state, { id, status }) {
    const pendency =
      state.pendencies.find(
        ({ billActivityPendencyId }) => billActivityPendencyId === id
      ) ||
      state.otherBillPendencies.find(
        ({ billActivityPendencyId }) => billActivityPendencyId === id
      );

    if (pendency) {
      Vue.set(pendency, 'status', status);
    }
  },

  UPDATE_PENDENCY(state, pendency) {
    const index = state.pendencies.findIndex(
      ({ billActivityPendencyId }) =>
        +billActivityPendencyId === +pendency.billActivityPendencyId
    );

    if (index !== -1) {
      Vue.set(state.pendencies, index, pendency);
      return;
    }

    const otherBillIndex = state.otherBillPendencies.findIndex(
      ({ billActivityPendencyId }) =>
        +billActivityPendencyId === +pendency.billActivityPendencyId
    );

    if (index === -1) {
      return;
    }

    Vue.set(state.otherBillPendencies, otherBillIndex, pendency);
  },

  CURRENT_PENDENCY(state, current) {
    state.current = current;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async fetch({ commit, state }) {
    if (state.pendenciesType.length > 0) {
      return Promise.resolve();
    }

    try {
      const { data } = await Axios.get('pendencies');

      commit('SET_PENDENCIES_TYPE', data);
    } catch (e) {
      return Promise.reject(e);
    }
    return null;
  },

  async status({ commit, rootState }, { id, billActivityId, status, current }) {
    /**
     * We bail if we are trying to update the status
     * with the same status
     */
    if (status === current) {
      return;
    }

    const options = {
      root: true
    };

    /**
     * If we are toggling a checklist item from the current bill activity
     * we update it too
     */
    const isCurrentBillActivity =
      rootState &&
      rootState.bill &&
      rootState.bill.bill &&
      +rootState.bill.bill.billActivityId === +billActivityId;

    /**
     * Update status helper function.
     */
    const update = (_status, _current) => {
      commit('SET_STATUS', {
        id,
        status: _status
      });

      const value = resolveStatus(_status, _current);

      if (isCurrentBillActivity) {
        commit('bill/ADD_TO_OPEN_COUNT', value, options);
      }

      commit(
        'activity/ADD_TO_OPEN_COUNT',
        {
          value,
          id: billActivityId
        },
        options
      );

      const user =
        status === 'DONE' || status === 'CANCELED'
          ? rootState && rootState.auth && rootState.auth.user
          : {};

      commit('SET_FINISHED_BY', {
        id,
        ...user
      });
    };

    update(status, current);

    try {
      const { data } = await Axios.put(
        `bill_activity_pendencies/${id}/status/${status}`
      );
      commit('UPDATE_PENDENCY', data);
    } catch (e) {
      update(current, status);
    }
  },

  /**
   * Create a new pendency.
   */
  async save(
    { commit, rootState, dispatch },
    {
      billId,
      pendency,
      activity_id,
      bill_activity_id,
      bill_activity_pendency_id,
      attachments_to_delete
    }
  ) {
    commit('SET_BUSY', 'save_pendency');
    const { data } = await dispatch('updateOrCreate', {
      pendency,
      billId,
      activity_id,
      bill_activity_pendency_id,
      attachments_to_delete
    });

    if (typeof data.billActivityPendencyId === 'number') {
      commit('CURRENT_PENDENCY', data.billActivityPendencyId);
      if (data.attachments != null && data.attachments.length > 0) {
        data.attachments.forEach((dt, index, res) => {
          /* eslint no-param-reassign: "error" */
          res[index].url = res[index].url.replace('temp-', '');
        });
      }
    }

    if (+rootState.bill.bill.billId === +billId) {
      const options = {
        root: true
      };

      if (
        !bill_activity_pendency_id &&
        +activity_id === +rootState.activity.activity
      ) {
        commit('PUSH_PENDENCY', data);
        commit('bill/ADD_TO_OPEN_COUNT', 1, options);
        commit(
          'activity/ADD_TO_OPEN_COUNT',
          {
            value: 1,
            id: bill_activity_id
          },
          options
        );

        commit('SET_BUSY');
        return;
      }

      commit('UPDATE_PENDENCY', data);
    }
    commit('SET_BUSY');
  },
  async updateOrCreate(
    { dispatch },
    {
      pendency,
      billId,
      activity_id,
      bill_activity_pendency_id,
      attachments_to_delete
    }
  ) {
    const attachmentsToCreate = pendency.attachments
      .filter(({ url }) => !url)
      .map((f) => {
        const file = new File([f.file], AttachmentUtil.normalizeTitle(f.name), {
          type: f.file.type
        });
        file.title = AttachmentUtil.normalizeTitleWithoutExtension(file.name);
        file.description = AttachmentUtil.normalizeDescription(f.description);
        return file;
      });

    const attachmentsCreated = pendency.attachments.filter(({ url }) => url);

    if (!attachmentsToCreate || !attachmentsToCreate.length) {
      pendency.attachments = attachmentsCreated;
    } else {
      const attachmentsRecentlyCreated = await dispatch(
        'attachment/createValidUrlAndPutFiles',
        {
          files: attachmentsToCreate,
          billId
        },
        { root: true }
      );

      await Promise.all(attachmentsRecentlyCreated).then((result) => {
        pendency.attachments = [...result, ...attachmentsCreated];
      });
    }

    if (bill_activity_pendency_id) {
      attachments_to_delete.forEach(({ attachmentId }) => {
        dispatch('attachment/deleteAttachment', attachmentId, { root: true });
      });
      return Axios.put(
        `bill_activity_pendencies/${bill_activity_pendency_id}`,
        pendency
      );
    }

    return Axios.post(
      `bills/${billId}/activities/${activity_id}/pendencies`,
      pendency
    );
  },

  setEditing({ commit }, pendency) {
    commit('SET_EDITING', { ...pendency });
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
