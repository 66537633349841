import Vue from 'vue';

import UserUtil from '@/modules/Settings/Modules/User/Utils';

import { getDefaultState } from './activities.state';

export default {
  SET_ACTIVITIES(state, activities) {
    state.activities = activities;
  },

  SET_ACTIVITY(state, activity) {
    state.activity = activity;
  },

  SET_PAGINATION(
    state,
    pagination = {
      total: 0,
      pages: 0,
      perPage: UserUtil.limitPerPage,
      current_page: 1
    }
  ) {
    state.pagination = pagination;
  },

  SET_STATUS(
    state,
    status = {
      warn: 0,
      danger: 0,
      success: 0,
      total: 0,
      totalAmount: 0
    }
  ) {
    state.status = status;
  },

  SET_LIST(state, list = []) {
    state.list = list;
  },

  SET_SEND_TO(state, id = null) {
    state.send_to = id;
  },

  SET_SEARCH(state, search) {
    state.search = search;
  },

  SET_SEARCH_CODE(state, searchCode) {
    state.searchCode = searchCode;
  },

  SET_IS_VALID_TO_FORWARD(state, isValidToForward) {
    state.is_valid_to_forward = isValidToForward;
  },

  SET_FORWARDED(state, forwarded) {
    state.forwarded = forwarded;
  },

  SET_FILTER(state, filter) {
    state.filter = filter;
  },

  SET_RESPONSIBLE_FILTER(state, responsibleFilter) {
    state.responsibleFilter = responsibleFilter;
  },

  SET_ORDER_FILTER(state, filter) {
    state.orderFilter = filter;
  },

  SET_TIMERANGE_FILTER(state, filter) {
    state.timeRangeFilter = filter;
  },

  SET_TIMERANGE_TYPE_FILTER(state, filter) {
    state.timeRangeTypeFilter = filter;
  },

  SET_FLAG_FILTER(state, filter) {
    state.flagFilter = filter;
  },

  SET_TYPE_FILTER(state, filter) {
    state.typeFilter = filter;
  },

  UPDATE_PAGINATION(state, { total, pages }) {
    state.pagination.total = total;
    state.pagination.pages = pages;
  },

  SET_PAGE(state, page) {
    state.pagination.current_page = page;
  },

  SET_LOT_ID(state, friendlyBillActivityLotId) {
    state.friendlyBillActivityLotId = friendlyBillActivityLotId;
  },

  SET_SELECTED(state, selected = []) {
    state.selected = selected;
  },

  SET_REMOVE(state, ids = []) {
    state.remove = ids;
  },

  SET_CANCEL_TOKEN(state, cancelToken) {
    state.cancelToken = cancelToken;
  },

  SET_HAVE_CANCELLED(state, haveCancelled) {
    state.haveCancelled = haveCancelled;
  },

  SELECT(state, id) {
    if (state.selected.find((selected) => +selected.billActivityId === +id)) {
      return;
    }
    const {
      patientName,
      billSources,
      billActivityId,
      billType,
      sourceBilledId,
      healthInsuranceName,
      forwardFriendlyLotId,
      openPendencyCount
    } = state.list.find((billActivity) => +billActivity.billActivityId === +id);

    state.selected.push({
      patientName,
      billSources,
      billActivityId: +billActivityId,
      billType,
      sourceBilledId,
      healthInsuranceName,
      forwardFriendlyLotId,
      openPendencyCount
    });
  },

  SELECT_MULTIPLE(state, ids) {
    const toPush = ids.filter(
      (id) =>
        !state.selected
          .map((selected) => +selected.billActivityId)
          .includes(+id)
    );
    if (toPush?.length < 1) {
      return;
    }

    const toPushObjects = toPush.map((id) => {
      const {
        patientName,
        billSources,
        billActivityId,
        billType,
        sourceBilledId,
        healthInsuranceName,
        forwardFriendlyLotId
      } = state.list.find(
        (billActivity) => +billActivity.billActivityId === +id
      );

      return {
        patientName,
        billSources,
        billActivityId: +billActivityId,
        billType,
        sourceBilledId,
        healthInsuranceName,
        forwardFriendlyLotId
      };
    });

    state.selected = state.selected.concat(toPushObjects);
  },

  SELECT_FROM_OBJECT(
    state,
    {
      patientName,
      billSources,
      billActivityId,
      billType,
      sourceBilledId,
      healthInsuranceName,
      forwardFriendlyLotId
    }
  ) {
    if (
      state.selected.find(
        (selected) => selected.billActivityId === +billActivityId
      )
    ) {
      return;
    }
    state.selected.push({
      patientName,
      billSources,
      billActivityId: +billActivityId,
      billType,
      sourceBilledId,
      healthInsuranceName,
      forwardFriendlyLotId
    });
  },

  REMOVE_SELECTED(state, index) {
    state.selected.splice(index, 1);
  },

  REMOVE_SELECTEDS_FROM_ID(state, billActivityIds) {
    if (!billActivityIds.length) {
      return;
    }
    state.selected = state.selected.filter(
      (item) => !billActivityIds.includes(+item.billActivityId)
    );
  },

  ADD_TO_DONE_COUNT(state, { id, value }) {
    const item = state.list.find(
      (billActivity) => +billActivity.billActivityId === +id
    );

    if (item) {
      Vue.set(item, 'doneChecklistCount', item.doneChecklistCount + value);
    }
  },

  ADD_TO_OPEN_COUNT(state, { id, value }) {
    const item = state.list.find(
      (billActivity) => +billActivity.billActivityId === +id
    );

    if (item) {
      Vue.set(item, 'openPendencyCount', item.openPendencyCount + value);
    }
  },
  SET_ERRORS(state, errors = []) {
    state.errors = errors;
  },

  SET_NOT_FOUND(state, notFound = null) {
    state.notFound = notFound;
  },

  REMOVE_SEARCH_ITEM(state, searchValue) {
    state.search = state.search
      ?.split(',')
      ?.filter((value) => value !== searchValue)
      ?.join(',');
  },

  REMOVE_NOT_FOUND_ITEM(state, { searchCode, searchValue }) {
    const itemsNotFound = state.notFound?.items?.find(
      (value) => value.searchCode === searchCode
    );
    if (!!itemsNotFound) {
      itemsNotFound.values = itemsNotFound.values.filter(
        (value) => value !== searchValue
      );
    }
  },

  SET_LIST_BACKWARD(state, listBackward) {
    state.listBackward = listBackward;
  },
  SET_SEARCH_CODE_OPTIONS(state, searchCodeOptions) {
    state.searchCodeOptions = searchCodeOptions;
  },

  SET_RESPONSIBLE(state, { user, billActivityId }) {
    const billActivityIndex = state.list.findIndex(
      (billActivity) => billActivity.billActivityId === billActivityId
    );

    if (billActivityIndex < 0) {
      return;
    }

    if (!user) {
      state.list[billActivityIndex].responsible = null;
      return;
    }

    state.list[billActivityIndex].responsible = { ...user };
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};
