import { pipe, useBusy, useErrors } from '@/helpers';

import actions from './activities.actions';
import getters from './activities.getters';
import mutations from './activities.mutations';
import { state } from './activities.state';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

export default useHelpers({
  state,
  actions,
  getters,
  mutations,
  namespaced
});
