import Axios from '@/axios';

export default class UpdateAppUtil {
  static instance = new UpdateAppUtil();

  currentHash: string;

  constructor() {
    this.initVersionCheck();
  }

  async getVersionFromFile() {
    try {
      const fileResponse = await Axios.get(
        `${window.location.origin}/version.json?q=${new Date().getTime()}`
      );

      return fileResponse?.data?.buildVersion;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async initVersionCheck() {
    if (!process.env.APPLICATION_VERSION) {
      return;
    }

    this.currentHash = process.env.APPLICATION_VERSION;
  }

  async checkVersion() {
    if (!this.currentHash) {
      return;
    }

    const newHash = await this.getVersionFromFile();
    this.reloadAppIfChanged(newHash);
  }

  reloadAppIfChanged(newHash: string) {
    const hashChanged = !!newHash && newHash !== this.currentHash;

    if (!hashChanged) {
      return;
    }

    this.currentHash = newHash;
    //calling window.location.reload(true) is deprecated, forcing relad using a POST request
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = location.href;
    document.body.appendChild(form);
    form.submit();
  }
}
