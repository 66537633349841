// import Vue from 'vue';
import { pipe, useBusy, useErrors } from '@/helpers';
import { state } from './user.state';
import getters from './user.getters';
import mutations from './user.mutations';
import actions from './user.actions';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

export default useHelpers({
  state,
  actions,
  getters,
  mutations,
  namespaced
});
