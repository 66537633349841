import Service from '@/core/Services/service';
import {
  IBillActivityDocumentLot,
  IBillActivityDocumentReceiveNew,
  IBillActivityDocuments
} from '@/core/Interfaces/bill_activity_document';

class Factory extends Service {
  async receiveNew(params: {
    data: IBillActivityDocumentReceiveNew;
    billActivityId?: number;
  }) {
    await this.post('documents/receive_new', params);
  }

  async getAllDocuments(
    billActivityIds: string | number | number[]
  ): Promise<IBillActivityDocumentLot[]> {
    const param =
      typeof billActivityIds === 'string' || typeof billActivityIds === 'number'
        ? billActivityIds
        : billActivityIds.join(',');
    const result = await this.get(`documents/bill-activities/${param}/new`);
    return result?.data;
  }

  async getDocuments(
    billActivityIds: string | number[],
    forwardedOnly: boolean
  ): Promise<IBillActivityDocuments[]> {
    const param =
      typeof billActivityIds === 'string'
        ? billActivityIds
        : billActivityIds.join(',');
    const result = await this.get(`documents/bill_activities/${param}`, {
      forwardedOnly
    });
    return result?.data;
  }
}

export default new Factory();
