import Service from '@/core/Services/service';

class Factory extends Service {
  async updateChecklist(id, status) {
    const result = await this.put(
      `bill_activity_checklist_items/${id}/status/${status}`
    );
    return result.data;
  }
}

export default new Factory();
