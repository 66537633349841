class AllBillsUtil {
  searchByBillIdDisplayLabel: string;

  searchByBillIdRequestLabel: string;

  constructor() {
    this.searchByBillIdDisplayLabel = 'peg_id:';
    this.searchByBillIdRequestLabel = 'peg_id';
  }

  convertSearchString(search: string) {
    const displayLabelLength = this.searchByBillIdDisplayLabel.length;

    const substrToValidade = search
      .trim()
      .substr(0, displayLabelLength)
      .toLowerCase();

    if (substrToValidade === this.searchByBillIdDisplayLabel) {
      const billIdValueToSearch = search
        .trim()
        .substr(displayLabelLength)
        .trim();
      return `${this.searchByBillIdRequestLabel}${billIdValueToSearch}`;
    }

    return search.trim();
  }
}

export default new AllBillsUtil();
