import UserUtil from '@/modules/Settings/Modules/User/Utils';
import { AllBillsStore } from '@types';

export const getDefaultState: () => AllBillsStore = () => {
  return {
    list: [],

    search: '',
    searchCode: '',
    searchCodeOptions: [],
    notFound: null,

    pagination: {
      total: 0,
      pages: 0,
      perPage: UserUtil.limitPerPage,
      current_page: 1
    },

    cancelToken: null,
    haveCancelled: false
  };
};

export const state = getDefaultState();
