import Vue from 'vue';
import Axios from 'axios';
import Config from '@/config';

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  baseURL: Config('api_url')
});

axios.interceptors.response.use(
  (response) => {
    Vue.config.defaultError = false;
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      Vue.config.store.dispatch('auth/logout');
      Vue.config.router.push('/auth');
    } else if (!Axios.isCancel(error)) {
      Vue.config.defaultError = true;
    }

    return Promise.reject(error);
  }
);

export default axios;

export const client = Axios;
