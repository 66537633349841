import { Route } from 'vue-router';

import Store from '@/core/Store';
import RoutePath from '@/route-path';

const MainLayout = () =>
  import(
    /* webpackChunkName: "core-main-layout" */
    '@/core/Components/MainLayout'
  );
const BillMain = () =>
  import(
    /* webpackChunkName: "bill-main-page" */
    '@/modules/Bill/Pages/BillMain'
  );
const BillActivitiesFlow = () =>
  import(
    /* webpackChunkName: "bill-activities-flow-page" */
    '@/modules/Bill/Pages/BillActivitiesFlow'
  );
const BillDocumentFlow = () =>
  import(
    /* webpackChunkName: "bill-documents-flow-page" */
    '@/modules/Bill/Pages/BillDocumentFlow'
  );
const BillAttachments = () =>
  import(
    /* webpackChunkName: "bill-attachments-page" */
    '@/modules/Bill/Pages/BillAttachments.vue'
  );
const BillComments = () =>
  import(
    /* webpackChunkName: "bill-comments-page" */
    '@/modules/Bill/Pages/BillComments'
  );

const AllBills = () =>
  import(
    /* webpackChunkName: "all-bills-main-page" */
    '@/modules/AllBills/Pages/AllBills'
  );

const protectedRoute = async (
  to: Route,
  from: Route,
  next: CallableFunction
) => {
  if (to.path === RoutePath.AUTH_LOGIN) {
    next();
    return;
  }
  next(await Store.dispatch('auth/check', false));
};

export default [
  {
    path: RoutePath.ALL_BILLS,
    component: MainLayout,
    beforeEnter: protectedRoute,
    meta: {
      title: 'Todas as contas',
      search: {
        source: 'allBills'
      }
    },
    children: [
      {
        name: RoutePath.ALL_BILLS,
        path: RoutePath.ALL_BILLS,
        component: AllBills,
        props: true
      },
      {
        path: RoutePath.ALL_BILLS_BILL,
        name: RoutePath.ALL_BILLS_BILL,
        component: BillMain,
        beforeEnter: protectedRoute,
        props: true,
        redirect: RoutePath.ALL_BILLS_BILL_DOCUMENTS_FLOW,
        children: [
          {
            path: RoutePath.ALL_BILLS_BILL_DOCUMENTS_FLOW,
            name: RoutePath.ALL_BILLS_BILL_DOCUMENTS_FLOW,
            component: BillDocumentFlow
          },
          {
            path: RoutePath.ALL_BILLS_BILL_ACTIVITIES_FLOW,
            name: RoutePath.ALL_BILLS_BILL_ACTIVITIES_FLOW,
            component: BillActivitiesFlow
          },
          {
            path: RoutePath.ALL_BILLS_BILL_COMMENTS,
            name: RoutePath.ALL_BILLS_BILL_COMMENTS,
            component: BillComments,
            props: true
          },
          {
            path: RoutePath.ALL_BILLS_BILL_ATTACHMENTS,
            name: RoutePath.ALL_BILLS_BILL_ATTACHMENTS,
            component: BillAttachments,
            props: true
          }
        ]
      }
    ]
  }
];
