import Service from '@/core/Services/service';
import { StreamSaverUtilClass } from '@/core/Utils/stream-saver';

class Factory extends Service {
  async getCompleteDashboard(dashboardId) {
    const result = await this.get(`dashboards/${dashboardId}/complete`);
    return result.data;
  }

  async getGroupsDashboard(dashboardId) {
    const result = await this.get(`dashboards/${dashboardId}/groups`);
    return result.data;
  }

  async getGroupsDashboardById(dashboardId, groupId) {
    const result = await this.get(
      `dashboards/${dashboardId}/groups/${groupId}/activities`
    );
    return result.data;
  }

  async getHeaderDashboard(dashboardId) {
    const result = await this.get(`dashboards/${dashboardId}/header`);
    return result.data;
  }

  /**
   * @deprecated
   */
  async getOverallDashboard(dashboardId) {
    const result = await this.get(`dashboards/${dashboardId}/overall`);
    return result.data;
  }

  async getOverviewDashboard(dashboardId) {
    const result = await this.get(`dashboards/${dashboardId}/overview`);
    return result.data;
  }

  async getBillActivitiesDashboard(dashboardId, params) {
    const result = await this.get(
      `dashboards/${dashboardId}/bill_activities`,
      params
    );
    return result.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async downloadbillActivitiesReport(dashboardId, dashboardName, query) {
    const streamSaver = new StreamSaverUtilClass('application/vnd.ms-excel');
    const formattedQuery = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');
    await streamSaver.fetchAndDownload(
      `dashboards/${dashboardId}/bill_activities_report${
        formattedQuery ? `?${formattedQuery}` : ''
      }`,
      dashboardName
    );
  }

  // CRUD part
  async getAllDashboards() {
    const result = await this.get('dashboards');
    return result.data;
  }

  async deleteDashboard(dashboardId) {
    const result = await this.delete(`dashboards/${dashboardId}`);
    return result.data;
  }

  async updateDashboard(dashboardId, params) {
    const result = await this.put(`dashboards/${dashboardId}`, params);
    return result.data;
  }

  async createDashboard(params) {
    const result = await this.post('dashboards', params);
    return result.data;
  }
}

export default Factory;
