/* eslint-disable class-methods-use-this */
import { INotification } from '@/core/Interfaces/notification';

class NotificationUtil {
  timeToRequest: number;

  timeToHideFloating: number;

  deleteNotificationsStorageKey: string;

  constructor() {
    this.timeToRequest = 1 * 60000; // in minutes
    this.timeToHideFloating = 5 * 1000; // in seconds

    this.deleteNotificationsStorageKey = '__peg_dna';
  }

  set notificationsToDelete(notifications: string) {
    window.localStorage.setItem(
      this.deleteNotificationsStorageKey,
      notifications
    );
  }

  get notificationsToDelete(): string {
    return window.localStorage.getItem(this.deleteNotificationsStorageKey);
  }

  isNotificationInArray(notification: INotification, arr: INotification[]) {
    return this.isNotificationInArrayId(
      notification,
      arr.map((n) => n.notificationId)
    );
  }

  isNotificationInArrayId(notification: INotification, arr: number[]) {
    return arr.includes(notification.notificationId);
  }

  hasToNotificate(notifications: INotification[], lastSeen: number) {
    return notifications.filter(
      ({ notificationId, readed }) => notificationId > lastSeen && !readed
    );
  }

  sortArrayById(notifications: INotification[]) {
    return notifications.sort(this.sortById);
  }

  sortById(a: INotification, b: INotification) {
    return b.notificationId - a.notificationId;
  }

  handleUnreaded(state: { unread: number }, action = 'subtract', value = 1) {
    if (action === 'subtract') {
      if (state.unread <= value) {
        state.unread = 0;
        return;
      }
      state.unread -= value;
      return;
    }
    state.unread += value;
  }
}

export default new NotificationUtil();
