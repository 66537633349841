import { chunk } from '@/helpers';
import UserUtil from '@/modules/Settings/Modules/User/Utils';
import { AllBillsStore } from '@types';

export default {
  /**
   * If we are doing a search.
   */
  searching: (state: AllBillsStore) => !!state.search,

  /**
   * Return all bill activities in row > col model.
   */
  chunks: (state: AllBillsStore) => chunk(state.list, UserUtil.limitPerPage),
  search: (state: AllBillsStore) => state.search,
  searchCode: (state: AllBillsStore) => state.searchCode,
  searchCodeOptions: (state: AllBillsStore) => state.searchCodeOptions,
  notFound: (state: AllBillsStore) => state.notFound,
  pagination: (state: AllBillsStore) => state.pagination,
  list: (state: AllBillsStore) => state.list,
  cancelToken: (state: AllBillsStore) => state.cancelToken,
  haveCancelled: (state: AllBillsStore) => state.haveCancelled
};
