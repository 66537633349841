import { IBillGroupStore } from './billGroup.state';

export default {
  selectedBillActivities: (state: IBillGroupStore) =>
    state.selectedBillActivities,
  selectedBillActivityIds: (state: IBillGroupStore) =>
    state.selectedBillActivities.map(({ billActivityId }) => billActivityId),
  indeterminateBillGroupIds: (state: IBillGroupStore) =>
    state.indeterminateBillGroupIds,
  selectedBillGroups: (state: IBillGroupStore) => state.selectedBillGroups,
  selectedBillGroupIds: (state: IBillGroupStore) =>
    state.selectedBillGroups.map(({ billGroupId }) => billGroupId),
  removingBillGroupIds: (state: IBillGroupStore) => state.removingBillGroupIds
};
