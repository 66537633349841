export default {
  SETTINGS: '/configuracoes',

  SETTINGS_HEALTH_INSURANCES: '/configuracoes/convenio',

  SETTINGS_USER: '/configuracoes/usuario',
  SETTINGS_USER_ACTIVITY: '/configuracoes/usuario/atividade/:activity_id?',
  SETTINGS_USER_CREATE: '/configuracoes/usuario/novo-usuario',
  SETTINGS_USER_DETAIL: '/configuracoes/usuario/:user_id/detalhes',

  SETTINGS_MOVE_RULES: '/configuracoes/regras-de-movimentacao',
  SETTINGS_MOVE_RULES_CREATE: '/configuracoes/regras-de-movimentacao/adicionar',

  SETTINGS_SECTORS: '/configuracoes/setores'
};
