import IUser from '@/modules/Settings/Modules/User/Interfaces/user';
import IAuthStore from '../Interfaces/store';

export const getDefaultState: () => IAuthStore = () => {
  return {
    user: new IUser(),
    status: null,
    token: window.localStorage.getItem('token'),
    company_id: window.localStorage.getItem('company_id'),
    phoneNumber: '',
    email: '',
    username: '',
    name: '',
    code: '',
    remainingLoginAttemps: null,
    deviceIdentity: null
  };
};

export const state = getDefaultState();
