import { IUserActivityFull } from './activity';
import { IUserAuthority } from './authority';

class IUser {
  constructor() {
    this.userId = null;
    this.name = null;
    this.userAuthorities = [];
    this.username = null;
    this.userActivities = [];
    this.quickSightUserArn = null;
    this.pegCompany = false;
  }

  userId: number;
  name: string;
  userAuthorities: IUserAuthority[];
  username: string;

  companyId: number;
  email: string;
  enabled: boolean;
  phoneNumber: string;
  userActivities: IUserActivityFull[];
  quickSightUserArn?: string;
  pegCompany: boolean;
}

export default IUser;
