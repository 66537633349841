import DashboardRoutePaths from '@/modules/Dashboard/Routes/paths';
import AuthRoutePaths from '@/modules/Auth/Routes/paths';
import ActivitiesRoutePaths from '@/modules/Activities/Routes/paths';
import AllBillsRoutePaths from '@/modules/AllBills/Routes/paths';
import SettingsRoutePaths from '@/modules/Settings/Routes/paths';
import PerformancePaths from '@/modules/Performance/Routes/paths';
import PanelsPaths from '@/modules/Panels/Routes/paths';

const RoutePath = {
  ...AuthRoutePaths,

  MAIN: '/',

  ...ActivitiesRoutePaths,
  ...AllBillsRoutePaths,
  ...DashboardRoutePaths,
  ...SettingsRoutePaths,
  ...PerformancePaths,
  ...PanelsPaths
};

export default RoutePath;
