import axios from '@/axios';

export default class Service {
  constructor() {
    this.service = axios;
  }

  async get(path, params = {}, cancelToken = null) {
    return await this.service.get(path, { params, cancelToken });
  }

  async post(path, params = {}, requestProps = {}) {
    return await this.service.post(path, params, requestProps);
  }

  async put(path, params = {}) {
    return await this.service.put(path, params);
  }

  async patch(path, params = {}) {
    return await this.service.patch(path, params);
  }

  async delete(path, params = {}) {
    return await this.service.delete(path, { data: params });
  }
}
