import { Route } from 'vue-router';

import Store from '@/core/Store';
import RoutePath from '@/route-path';
const MainLayout = () =>
  import(
    /* webpackChunkName: "core-main-layout" */
    '@/core/Components/MainLayout'
  );

const Panels = () =>
  import(/* webpackChunkName: "panels-main-page" */ '../Pages/Panels');
const PanelsDetail = () =>
  import(/* webpackChunkName: "panels-detail-page" */ '../Pages/PanelsDetail');

// eslint-disable-next-line @typescript-eslint/ban-types
const protectedRoute = async (to: Route, _from: Route, next: Function) => {
  if (to.path === '/auth/login') {
    next();
    return;
  }
  const result = await Store.dispatch('auth/check', false);

  if (!Store.getters['auth/canCurrentUserSeePanel']) {
    next({
      name: RoutePath.ACTIVITY
    });
    return;
  }
  next(result);
};

export default [
  {
    path: RoutePath.PANELS,
    component: MainLayout,
    meta: {
      title: 'Painéis'
    },
    children: [
      {
        path: RoutePath.PANELS,
        name: RoutePath.PANELS,
        beforeEnter: protectedRoute,
        component: Panels,
        children: [
          {
            path: RoutePath.PANELS_DETAIL,
            name: RoutePath.PANELS_DETAIL,
            beforeEnter: protectedRoute,
            component: PanelsDetail
          }
        ]
      }
    ]
  }
];
