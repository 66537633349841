import Service from '@/core/Services/service';

class Factory extends Service {
  async getHealthInsurance() {
    return await this.get('health_insurances');
  }

  async updateDeadlineDays(params) {
    return await this.post('health_insurances/update-deadline', params);
  }
}

export default new Factory();
