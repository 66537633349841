import Vue from 'vue';
import { pipe, useBusy, useErrors, capitalizeSentence } from '@/helpers';
import Axios from '@/axios';
import BillFactory from '@/core/Services/bill';

const useHelpers = pipe(useBusy, useErrors);

const namespaced = true;

const getDefaultState = () => ({
  bill: {},
  coverTypes: []
});

const theState = getDefaultState();

const getters = {
  bill: (state) => state.bill,
  coverTypes: (state) => state.coverTypes
};

const mutations = {
  SET_BILL(state, bill = {}) {
    state.bill = bill;
  },

  SET_COVER_TYPES(state, coverTypes = []) {
    state.coverTypes = coverTypes;
  },

  SET_CHECKLIST(state, checklist = []) {
    state.checklist = checklist;
  },

  SET_FIRST_VISUALIZATION(state, firstVisualization) {
    Vue.set(state.bill, 'firstVisualizationDate', firstVisualization);
  },

  ADD_TO_DONE_COUNT(state, value) {
    Vue.set(
      state.bill,
      'doneChecklistCount',
      state.bill.doneChecklistCount + value
    );
  },

  ADD_TO_OPEN_COUNT(state, value) {
    Vue.set(
      state.bill,
      'openPendencyCount',
      state.bill.openPendencyCount + value
    );
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },

  UPDATE_VALID_TO_FORWARD(state, isValidToForward) {
    state.bill.isValidToForward = isValidToForward;
  },

  UPDATE_RESPONSIBLE(state, responsible) {
    state.bill.responsible = responsible;
  }
};

const actions = {
  async reset({ commit }) {
    commit('SET_BILL');
    commit('SET_CHECKLIST');
  },

  async visualize({ commit, dispatch, state }, id) {
    commit('activity/SET_REMOVE', [id], {
      root: true
    });

    if (state.bill.billActivityId) {
      commit('SET_FIRST_VISUALIZATION', Date.now());
    }

    try {
      await Axios.post(`bill_activities/${id}/visualize`);

      await dispatch('activity/bill', null, {
        root: true
      });
    } catch (e) {
      commit('activity/SET_REMOVE');
      commit('SET_FIRST_VISUALIZATION', null);

      return Promise.reject(e);
    }
    return null;
  },

  async fetch({ commit, dispatch }, billActivityId) {
    commit('SET_BUSY', 'fetch');

    try {
      const {
        checklists,
        pendencies,
        otherBillPendencies,
        billActivity,
        documentsByLot,
        otherBillOpenDocumentsByLot
      } = await BillFactory.getBillByActivity(billActivityId);

      /**
       * For some odd reason
       * bill doesn't include billActivityId
       */
      const bill = {
        ...billActivity,
        billActivityId,
        patientName: capitalizeSentence(billActivity.patientName)
      };

      commit('SET_BILL', bill);

      if (!bill.isValidToForward) {
        commit('activity/REMOVE_SELECTEDS_FROM_ID', [+billActivityId], {
          root: true
        });
      }

      commit('checklist/SET_CHECKLIST', checklists || [], {
        root: true
      });
      commit('pendency/SET_PENDENCIES', pendencies || [], {
        root: true
      });
      commit('pendency/SET_OTHER_BILL_PENDENCIES', otherBillPendencies || [], {
        root: true
      });
      commit('document/SET_DOCUMENTS', documentsByLot || [], {
        root: true
      });
      commit(
        'document/SET_OTHER_DOCUMENTS',
        otherBillOpenDocumentsByLot || [],
        {
          root: true
        }
      );
    } catch (e) {
      dispatch('reset');
    } finally {
      commit('SET_BUSY');
    }
  },

  async getBillById({ commit }, billId) {
    const result = await BillFactory.getBill(billId);
    const bill = {
      ...result,
      patientName: capitalizeSentence(result.patientName)
    };
    commit('SET_BILL', bill);
  },

  async checkBillisValidToForward({ commit }, billActivityId) {
    try {
      const isValidToForward = await BillFactory.isBillValidToForward(
        billActivityId
      );

      commit('UPDATE_VALID_TO_FORWARD', isValidToForward);

      if (!isValidToForward) {
        commit('activity/REMOVE_SELECTEDS_FROM_ID', [+billActivityId], {
          root: true
        });
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  updateResponsible({ commit }, responsible) {
    commit('UPDATE_RESPONSIBLE', responsible);
  },

  async getBillTypesByCompany({ commit }) {
    commit('SET_BUSY', 'coverTypes');
    try {
      const billTypesByCompany = await BillFactory.getBillTypesByCompany();
      commit('SET_COVER_TYPES', billTypesByCompany);
    } catch (err) {
      throw new Error(err);
    } finally {
      commit('SET_BUSY');
    }
  }
};

export default useHelpers({
  state: theState,
  actions,
  getters,
  mutations,
  namespaced
});
