export default {
  ACTIVITY: '/atividades/:activity_id?',
  ACTIVITY_BILL: '/atividades/:activity_id?/conta/:bill_activity_id?',
  ACTIVITY_BILL_ACTIVITIES_FLOW:
    '/atividades/:activity_id?/conta/:bill_activity_id?/fluxo-de-atividades',
  ACTIVITY_BILL_ATTACHMENTS:
    '/atividades/:activity_id?/conta/:bill_activity_id?/anexos',
  ACTIVITY_BILL_COMMENTS:
    '/atividades/:activity_id?/conta/:bill_activity_id?/comentarios',
  ACTIVITY_BILL_DOCUMENTS_FLOW:
    '/atividades/:activity_id?/conta/:bill_activity_id?/fluxo-de-documentos/:document_id?/:document_type_id?',

  ACTIVITY_GROUP: '/atividades/:activity_id?/grupo/:bill_group_id?',
  ACTIVITY_GROUP_BILL:
    '/atividades/:activity_id?/grupo/:bill_group_id?/conta/:bill_activity_id?',
  ACTIVITY_GROUP_BILL_ACTIVITIES_FLOW:
    '/atividades/:activity_id?/grupo/:bill_group_id?/conta/:bill_activity_id?/fluxo-de-atividades',
  ACTIVITY_GROUP_BILL_ATTACHMENTS:
    '/atividades/:activity_id?/grupo/:bill_group_id?/conta/:bill_activity_id?/anexos',
  ACTIVITY_GROUP_BILL_COMMENTS:
    '/atividades/:activity_id?/grupo/:bill_group_id?/conta/:bill_activity_id?/comentarios',
  ACTIVITY_GROUP_BILL_DOCUMENTS_FLOW:
    // eslint-disable-next-line max-len
    '/atividades/:activity_id?/grupo/:bill_group_id?/conta/:bill_activity_id?/fluxo-de-documentos/:document_id?/:document_type_id?'
};
