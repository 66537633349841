import { CancelTokenSource } from 'axios';
import IUserStore from '../Interfaces/store';
import { getDefaultState } from './user.state';

export default {
  SET_LIST(state: IUserStore, list: IUserStore['list'] = []) {
    state.list = list;
  },
  SET_SEARCH(state: IUserStore, search: IUserStore['search']) {
    state.search = search;
  },

  SET_PAGINATION(
    state: IUserStore,
    pagination = {
      total: 0,
      pages: 0,
      perPage: 10,
      current_page: 1
    }
  ) {
    state.pagination = pagination;
  },

  UPDATE_PAGINATION(
    state: IUserStore,
    {
      total,
      pages
    }: {
      total: IUserStore['pagination']['total'];
      pages: IUserStore['pagination']['pages'];
    }
  ) {
    state.pagination.total = total;
    state.pagination.pages = pages;
  },

  SET_PAGE(
    state: IUserStore,
    current_page: IUserStore['pagination']['current_page']
  ) {
    state.pagination.current_page = current_page;
  },

  SET_ACTIVITY_ID(state: IUserStore, activityId: number) {
    state.activityId = activityId;
  },

  SET_CANCEL_TOKEN(state: IUserStore, cancelToken: CancelTokenSource) {
    state.cancelToken = cancelToken;
  },

  SET_HAVE_CANCELLED(state: IUserStore, haveCancelled: boolean) {
    state.haveCancelled = haveCancelled;
  },

  RESET_STATE(state: IUserStore) {
    Object.assign(state, getDefaultState());
  }
};
