import streamSaver from 'streamsaver';
import Config from '@/config';
import axios from '@/axios';

streamSaver.WritableStream =
  streamSaver.WritableStream ??
  import(
    /* webpackChunkName: "web-streams-polyfill" */
    'web-streams-polyfill/ponyfill/es6'
  ).then((ponyfill) => ponyfill.WritableStream);
streamSaver.mitm = `${window.location.origin}/mitm.html`;

export class StreamSaverUtilClass {
  headers: any;

  constructor(contentType = 'application/*') {
    this.headers = {
      Accept: contentType,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': Config('api_url'),
      Authorization: axios.defaults.headers.common.Authorization,
      'x-client-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'x-company-id': axios.defaults.headers.common['x-company-id']
    };
  }

  async fetchAndDownload(
    url: string,
    fileName: string,
    postContent: any = null
  ) {
    const configUrl = Config('api_url');
    const baseUrl =
      configUrl.substr(-1) === '/'
        ? configUrl.substring(0, configUrl.length - 1)
        : configUrl;
    const requestUrl = url.substr(0) === '/' ? url.substring(1) : url;
    const init: RequestInit = { headers: this.headers };
    if (postContent != null) {
      init.method = 'POST';
      init.body = JSON.stringify(postContent);
    }

    await fetch(`${baseUrl}/${requestUrl}`, init).then((res) => {
      const readableStream = res.body;
      const fileStream = streamSaver.createWriteStream(fileName);

      if (window.WritableStream && readableStream.pipeTo) {
        return readableStream.pipeTo(fileStream).then(() => null);
      }

      const writer = fileStream.getWriter();

      const reader = res.body.getReader();
      const pump = () =>
        reader
          .read()
          .then((resReaded) =>
            resReaded.done
              ? writer.close()
              : writer.write(resReaded.value).then(pump)
          );

      pump();
      return null;
    });
  }
}

export const StreamSaverUtil = new StreamSaverUtilClass();
