import Service from '@/core/Services/service';

class Factory extends Service {
  async getBill(billId) {
    const { data } = await this.get(`/bills/${billId}`);
    return data;
  }

  async getBillByActivity(billActivityId) {
    const { data } = await this.get(`/bill_activities/${billActivityId}`);
    return data;
  }

  async isBillValidToForward(billActivityId) {
    const { data } = await this.get(
      `/bill_activities/${billActivityId}/is_valid_to_forward`
    );
    return data;
  }

  async getBillTypesByCompany() {
    const { data } = await this.get('/bills/types');
    return data;
  }
}

export default new Factory();
