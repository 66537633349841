import Vue from 'vue';
import Vuex from 'vuex';
import Config from '@/config';
import auth from '@/modules/Auth/Store';
import bill from '@/core/Store/bill';
import allBills from '@/modules/AllBills/Store';
import company from '@/core/Store/company';
import history from '@/core/Store/history';
import activity from '@/modules/Activities/Store';
import pendency from '@/core/Store/pendency';
import checklist from '@/core/Store/checklist';
import dashboard from '@/modules/Dashboard/Store';
import attachment from '@/core/Store/attachment';
import healthInsurance from '@/core/Store/healthInsurance';
import document from '@/core/Store/document';
import competence from '@/core/Store/competence';
import user from '@/modules/Settings/Modules/User/Store';
import notification from '@/core/Store/notification';
import billGroup from '@/core/Store/billGroup';
import supportActions from '@/core/Store/supportActions';

// eslint-disable-next-line
Vue.config.devtools = process.env.NODE_ENV === 'development';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: Config('env') !== 'production',
  modules: {
    allBills,
    activity,
    attachment,
    auth,
    bill,
    billGroup,
    checklist,
    company,
    competence,
    dashboard,
    document,
    healthInsurance,
    history,
    notification,
    pendency,
    user,
    supportActions
  },
  actions: {
    clearAllStates({ commit }) {
      commit('allBills/RESET_STATE');
      commit('activity/RESET_STATE');
      commit('attachment/RESET_STATE');
      commit('auth/RESET_STATE');
      commit('bill/RESET_STATE');
      commit('billGroup/RESET_STATE');
      commit('competence/RESET_STATE');
      commit('dashboard/RESET_STATE');
      commit('document/RESET_STATE');
      commit('healthInsurance/RESET_STATE');
      commit('history/RESET_STATE');
      commit('notification/RESET_STATE');
      commit('pendency/RESET_STATE');
      commit('user/RESET_STATE');
      commit('supportActions/RESET_STATE');
    }
  }
});
