class DashboardUtil {
  autoplayCarouselTime: number;

  dashboardBackground: string;

  progressesKeys: {
    label: string;
    singularLabel: string;
    key: string;
    detailKey: string;
  }[];

  constructor() {
    this.autoplayCarouselTime = 30 * 1000; // in seconds
    this.dashboardBackground = 'var(--colors-brand-fair)';
    this.progressesKeys = [
      {
        label: 'Contas liberadas',
        singularLabel: 'Conta liberada',
        key: 'patientDischargedValues',
        detailKey: 'PATIENT_DISCHARGED'
      },
      {
        label: 'Contas fechadas',
        singularLabel: 'Conta fechada',
        key: 'closedValues',
        detailKey: 'CLOSED'
      },
      {
        label: 'Remessas abertas',
        singularLabel: 'Remessa aberta',
        key: 'billedOpenedValues',
        detailKey: 'BILLED_OPENED'
      },
      {
        label: 'Remessas fechadas',
        singularLabel: 'Remessa fechada',
        key: 'billedClosedValues',
        detailKey: 'BILLED_CLOSED'
      },
      {
        label: 'Enviadas ao plano',
        singularLabel: 'Envio ao plano',
        key: 'sentToHealthInsuranceValues',
        detailKey: 'SENT_TO_HEALTH_INSURANCE'
      },
      {
        label: 'Jurídico',
        singularLabel: 'Jurídico',
        key: 'judicial',
        detailKey: 'JUDICIAL'
      }
    ];
  }
}

export default new DashboardUtil();
