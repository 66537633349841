export default {
  ALL_BILLS: '/todas-as-contas',
  ALL_BILLS_BILL: '/todas-as-contas/conta/:bill_id',
  ALL_BILLS_BILL_ATTACHMENTS: '/todas-as-contas/conta/:bill_id/anexos',
  ALL_BILLS_BILL_COMMENTS: '/todas-as-contas/conta/:bill_id/comentarios',
  ALL_BILLS_BILL_ACTIVITIES_FLOW:
    '/todas-as-contas/conta/:bill_id/fluxo-de-atividades',
  ALL_BILLS_BILL_DOCUMENTS_FLOW:
    '/todas-as-contas/conta/:bill_id/fluxo-de-documentos/:document_id?/:document_type_id?'
};
