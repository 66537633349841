import Service from '@/core/Services/service';
import TPagination from '@/core/Interfaces/pagination';
import { CancelToken } from 'axios';
import { AllBillsBill } from '@types';

class Factory extends Service {
  async getBills(
    params: any,
    cancelToken?: CancelToken
  ): Promise<TPagination<AllBillsBill>> {
    const result = await this.get('/bills', params, cancelToken);
    return result.data;
  }
}

export default new Factory();
