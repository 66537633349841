import IBillActivity from '@/core/Interfaces/bill_activity';
import IBillGroup from '@/core/Interfaces/bill_group';
import { IBillGroupStore, getDefaultState } from './billGroup.state';

export default {
  SELECT_BILL_ACTIVITIES(
    state: IBillGroupStore,
    billActivities: IBillActivity[]
  ) {
    const activitiesToAdd = billActivities.filter(
      ({ billActivityId }) =>
        !state.selectedBillActivities
          .map((billActivity) => billActivity.billActivityId)
          .includes(billActivityId)
    );
    state.selectedBillActivities = [
      ...state.selectedBillActivities,
      ...activitiesToAdd
    ];
  },

  UNSELECT_BILL_ACTIVITIES(
    state: IBillGroupStore,
    billActivities: IBillActivity[]
  ) {
    state.selectedBillActivities = state.selectedBillActivities.filter(
      ({ billActivityId }) =>
        !billActivities
          .map((billActivity) => billActivity.billActivityId)
          .includes(billActivityId)
    );
  },

  SELECT_BILL_GROUPS(state: IBillGroupStore, billGroups: IBillGroup[]) {
    billGroups.forEach((billGroup) => {
      if (
        state.selectedBillGroups
          .map((billGroupSelected) => billGroupSelected.billGroupId)
          .includes(billGroup.billGroupId)
      ) {
        return;
      }

      state.selectedBillGroups.push(billGroup);
    });
  },

  UNSELECT_BILL_GROUPS(state: IBillGroupStore, billGroupIds: number[]) {
    if (!billGroupIds.length) {
      return;
    }
    state.selectedBillGroups = state.selectedBillGroups.filter(
      (item) => !billGroupIds.includes(+item.billGroupId)
    );
  },

  SET_INDETERMINATE(state: IBillGroupStore, billGroupId: number) {
    const index = state.indeterminateBillGroupIds.findIndex(
      (indeterminateBillGroupId) => indeterminateBillGroupId === billGroupId
    );

    if (index > -1) {
      return;
    }

    state.indeterminateBillGroupIds.push(billGroupId);
  },

  UNSET_INDETERMINATE(state: IBillGroupStore, billGroupId: number) {
    const index = state.indeterminateBillGroupIds.findIndex(
      (indeterminateBillGroupId) => indeterminateBillGroupId === billGroupId
    );

    if (index < 0) {
      return;
    }

    state.indeterminateBillGroupIds.splice(index, 1);
  },

  UNSELECT_ALL(state: IBillGroupStore) {
    state.selectedBillGroups = [];
    state.selectedBillActivities = [];
    state.indeterminateBillGroupIds = [];
  },

  SET_REMOVING_BILL_GROUPS(state: IBillGroupStore, billGroupIds: number[]) {
    state.removingBillGroupIds = billGroupIds;
  },

  RESET_STATE(state: IBillGroupStore) {
    Object.assign(state, getDefaultState());
  }
};
