import { AutorityType } from '@/modules/Settings/Modules/User/Interfaces/authority';
import IAuthStore from '../Interfaces/store';
import Config from '@/config';

export default {
  isAdmin: (state: IAuthStore) =>
    state.user?.userAuthorities
      .map(({ authority }) => authority)
      .includes(AutorityType.ROLE_ADMIN),

  canCurrentUserEditUsers: (state: IAuthStore, getters: { isAdmin: boolean }) =>
    getters.isAdmin ||
    state.user?.userAuthorities
      .map(({ authority }) => authority)
      .includes(AutorityType.ROLE_USER_MANAGER),

  canCurrentUserSeePerformance: (state: IAuthStore) =>
    !!state.user?.quickSightUserArn,

  canCurrentUserSeeDashboard: (
    state: IAuthStore,
    getters: { isAdmin: boolean }
  ) =>
    getters.isAdmin ||
    state.user?.userAuthorities
      .map(({ authority }) => authority)
      .includes(AutorityType.ROLE_REPORTS_VIEWER),

  canCurrentUserSeeActivities: (
    state: IAuthStore,
    getters: { isAdmin: boolean }
  ) => getters.isAdmin || state.user?.userActivities?.length > 0,

  canCurrentUserSeePanel: (state: IAuthStore, getters: { isAdmin: boolean }) =>
    getters.isAdmin ||
    state.user?.userAuthorities
      .map(({ authority }) => authority)
      .some((authority) =>
        [
          AutorityType.ROLE_BILLINGS_VIEWER,
          AutorityType.ROLE_FINANCIAL_VIEWER
        ].includes(authority)
      ),

  isAdminUser: (state: IAuthStore) => state.user?.username === 'admin',

  insertProductionScripts: (
    _state: IAuthStore,
    getters: { isAdminUser: boolean }
  ) => {
    return !getters.isAdminUser || Config('env') === 'production';
  },

  isPegCompany: (state: IAuthStore) => !!state.user?.pegCompany,

  currentUser: (state: IAuthStore) => state.user,

  remainingLoginAttemps: (state: IAuthStore) => state.remainingLoginAttemps
};
