import { pipe, useBusy, useErrors } from '@/helpers';

import { state } from './dashboard.state';
import actions from './dashboard.actions';
import getters from './dashboard.getters';
import mutations from './dashboard.mutations';

const useHelpers = pipe(useBusy, useErrors);

/**
 * Dashboard store is namespaced.
 */
const namespaced = true;

export default useHelpers({
  state,
  actions,
  getters,
  mutations,
  namespaced
});
